module.exports = {
  defaultRankTsuikyaku: '未設定',
  defaultStaff: 0,
  defaultStaffOption: {key: null, value: '未設定'},
  noDataText: '未設定',
  kindHQAccount: '0',
  pageSizeList:[
    {
      key: 50,
      value: '50件'
    },
    {
      key: 100,
      value: '100件'
    },
    {
      key: 150,
      value: '150件'
    },
    {
      key: 200,
      value: '200件'
    },
  ],
}
