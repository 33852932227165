module.exports = {
  //文字列型の配列
  conditionArrayString: [
    'rankTsuikyaku',
    'companyClients',
    'ankenTypes',
    'ankenOndos',
    'transactionTypes',
    'actionTypes',
    'practitioners',
  ],
  //数値型の配列
  conditionArrayInt: [
    'tags',
    'ankentStatuses',
    'ondos',
    'status',
    'layouts',
    'tagMasterId',
  ],
  //文字列
  conditionString: [
    'flagUranashi',
    'mailKinsi',
    'jitakuyusoKinsi',
    'homonHuka',
    'portalMember',
    'shopMember',
    'kibouKodateNew',
    'kibouKodateOld',
    'kibouMansionNew',
    'kibouMansionOld',
    'kibouTochi',
    'kibouJigyo',
    'dateType',
    "mensekiTaniUnit",
    "tochiMensekiUnit"
  ],
  //スペースあり文字列(キーワード)
  conditionStrings: [
    'keywords',
  ],
  //数値
  conditionInt: [
    'tagsLogic',
    'kibouKodateNewKakakuMin',
    'kibouKodateNewKakakuMax',
    'kibouKodateOldKakakuMin',
    'kibouKodateOldKakakuMax',
    'kibouMansionNewKakakuMin',
    'kibouMansionNewKakakuMax',
    'kibouMansionOldKakakuMin',
    'kibouMansionOldKakakuMax',
    'kibouTochiKakakuMin',
    'kibouTochiKakakuMax',
    'kibouJigyoKakakuMin',
    'kibouJigyoKakakuMax',
    "mensekiMin",
    "mensekiMax",
    "tochiMensekiMin",
    "tochiMensekiMax",
    'shopId',
    'mainAdminId',
    'subAdminId',
    'mainShopUser',
    'subShopUser',
    'page',
    'matchingMail',
    'propertyId',
    'propertyNo',
  ],
  //日付
  conditionDate: [
    'inquiryReceivedDateStartedAt',
    'inquiryReceivedDateEndAt',
    'clientHistoryNitijiStart',
    'clientHistoryNitijiEnd',
    'createdAtStart',
    'createdAtEnd',
    'dateAtEnd',
    'dateAtStart',
    'nitijiStart',
    'nitijiEnd',
    'monthlySearchDate',
  ],
  //数値(日数/回数)
  conditionIntDaysTimes: [
    'mailOpenAction',
    'mailClickAction'
  ],
  //真偽g
  conditionBool: [
    'isKibouAnd',
  ],
  //媒体
  conditionMedia: [
    'buy',
    'other',
    'sell',
  ],
  //null許可数値
  conditionAcceptNull: [
    'clientId',
  ],
  //ソート
  conditionSort: [
    'target',
    'isAsc',
  ],
  conditionLocationInt: [
    'sikucyoson',
    'todohuken',
  ],
  conditionLocationArrayInt: [
    'cyoCds',
    'ensenIds',
    'ekiIds',
    'shogakkous',
    'tyugakkous',
  ],

}
