module.exports = {
  outputTypes: [
    { text: '月別表示', value: '2'},
    { text: '年別表示 ', value: '1'},
  ],
  path: {
    basicInformation: [
      'output_type',
      'start_at'
    ],
  },
  presence: '1',
  status: {
    beginExport: '1',
    inProgress: '2',
    complete: '3',
    completeWithError: '9',
  },
  pagination: {initPage: 1, pageSize: 50},
  yearType: '1',
  monthType: '2',
  outputting: '出力中',
  creating: '生成中',
  complete: '完了',
}
