module.exports = {
  deleteKeyList: [
    '__typename', 'baikyakuProperty', 'mainShopUser', 'subShopUser', 'seiyakuRelation',
  ],

  deleteDesiredConditionKeyList: [
    'baikyakuProperty',
    'mainShopUser',
    'subShopUser',
    'clientRelation',
    'deleted',
    'created_at',
    'created_user',
    'updated_at',
  ],

  deleteAreaKeyList: [
    '__typename',
    'todohukenRelation',
    'sikucyosonRelation',
    'ensenRelation',
    'startEkiRelation',
    'endEkiRelation',
  ],

  deleteAreaGakkouKeyList: [
    '__typename',
    'surroundingRelation',
    'category',
  ],

  deleteAreaAreaCyoKeyList: [
    'cyoRelation',
  ],

  deleteMadoryKeyList: [
    'madoriRelation',
  ],

  deleteKoukokuKatsudouKeyList: [
    'koukokuKatsudouRelation',
  ],

  deleteFileKeyList: [
    'created_at',
    'created_user',
    'kibou',
    'updated_at',
    'updated_user',
  ],

  deleteDoukisKeyList: [
    'doukiRelation',
  ],

  deleteKozosKeyList: [
    'kozoRelation',
  ],

  deletePropertyRelatePersonKeyList: [
    'propertyRelatedPerson',
  ],

  deleteMatchingConditionAreaKeyList: [
    '__typename',
    'todohukenRelation',
    'sikucyosonRelation',
    'startEkiRelation',
    'endEkiRelation',
  ],

  deleteMatchingConditionAreaGakkouKeyList: [
    '__typename',
    'surroundingRelation',
    'category',
  ],

  deleteDirectInputKeyList: [
    '__typename',
    'baikyaku_property_type',
    'baikyaku_property_type_sonota',
    'baikyaku_property_area',
    'baikyaku_property_building',
    'baikyaku_property_room_number',
    'baikyaku_property_kingaku',
    'baikyaku_property_tochi_menseki',
    'baikyaku_property_tatemono_menseki',
    'baikyaku_property_sotei_rimawari',
    'baikyaku_property_sotei_shunyu',
  ],

  pagination: {initPage: 1, pageSize: 50,},

  //あり・なしの定数
  presence: '1',
  absence: '0',

  //案件一覧　画面右側のステータスの定数
  projectStatus: [
    {text: "将来見込み", value: 100},
    {text: "未対応", value: 200},
    {text: "追客中", value: 300},
    {text: "他社受託", value: 410},
    {text: "自社受託", value: 420},
    {text: "他決", value: 510},
    {text: "中止", value: 520},
    {text: "保留", value: 530},
    {text: "売止", value: 540},
    {text: "買付済", value: 550},
    {text: "契約済", value: 600},
    {text: "決済済", value: 700},
  ],

  //買い、売りごとの案件一覧
  eachProjectStatus: [
    {type: 'b',　text: '買い', values: [100, 200, 300, 510, 520, 530, 550, 600, 700]},
    {type: 's', text: '売り', values: [100, 200, 300, 410, 420, 510, 520, 540, 550, 600, 700]}
  ],

  //あり・なしの選択肢
  existence: [
    {text: 'あり', value: '1'},
    {text: 'なし', value: '0'}
  ],

  mailFlag: [
    {text: '要', value: '1'},
    {text: '不要', value: '0'}
  ],

  //面積のチェック
  kibouUnit: {
    squareMeter: '1',
    tsubo: '2'
  },

  //物件種別のチェック
  isKibouTypeValue: '1',
  isPurchaseValue: 550,
  isContractValue: 600,
  isPaidValue: 700,
  isDirectInputValue: '0',
  isPropertyDBValue: '1',
  isTypeOtherValue: 'e',

  //その他の定数
  isSonota: 'e',

  //関係者との相談
  discussion: [
    {text: '相談済', value: '1'},
    {text: 'できていない', value: '2'}
  ],

  // 希望エリアの指定方法
  desiredType: [
    {text: 'エリアを指定', value: '0'}, {text: '学区を指定', value: '1'}, {text: '沿線・駅を指定', value: '2'},
  ],

  // 希望エリアの指定方法
  desiredName: [
    {text: 'エリア', value: '0'}, {text: '学区', value: '1'}, {text: '沿線・駅', value: '2'},
  ],

  // 希望エリアの定数
  selectDesiredType: {
    cyo: '0',
    school: '1',
    eki: '2',
  },

  // ５０音
  syllables: [
    {text: 'あ行', kana: ['ア', 'イ', 'ウ', 'エ', 'オ']},
    {text: 'か行', kana: ['カ', 'キ', 'ク', 'ケ', 'コ', 'ガ', 'ギ', 'グ', 'ゲ', 'ゴ']},
    {text: 'さ行', kana: ['サ', 'シ', 'ス', 'セ', 'ソ', 'ザ', 'ジ', 'ズ', 'ゼ', 'ゾ']},
    {text: 'た行', kana: ['タ', 'チ', 'ツ', 'テ', 'ト', 'ダ', 'ヂ', 'ヅ', 'デ', 'ド']},
    {text: 'な行', kana: ['ナ', 'ニ', 'ヌ', 'ネ', 'ノ']},
    {text: 'は行', kana: ['ハ', 'ヒ', 'フ', 'ヘ', 'ホ', 'バ', 'ビ', 'ブ', 'ベ', 'ボ', 'パ', 'ピ', 'プ', 'ペ', 'ポ']},
    {text: 'ま行', kana: ['マ', 'ミ', 'ム', 'メ', 'モ']},
    {text: 'や行', kana: ['ヤ', 'ユ', 'ヨ']},
    {text: 'ら行', kana: ['ラ', 'リ', 'ル', 'レ', 'ロ']},
    {text: 'わ行', kana: ['ワ', 'ヲ', 'ン']},
  ],

  // 学校カテゴリーの定数
  schoolCategory: {
    primarySchool: '013',
    juniorHighSchool: '014',
  },

  //マッチング条件設定選択肢
  matchingConditions: {
    buy: [
      {text: '購入希望条件と同じ', value: 1}, {text: '個別に設定', value: 2}
    ],
    sell: [
      {text: '設定する', value: 2}
    ],
  },

  // マッチング条件設定の定数
  selectMatchingType: {
    sameDesire: 1,
    original: 2,
    noSet: 0,
  },

  //築年数の選択肢
  buildingAge: [
    {key: 1, value: 1}, {key: 3, value: 3}, {key: 5, value: 5}, {key: 7, value: 7},
    {key: 10, value: 10}, {key: 15, value: 15}, {key: 20, value: 20}, {key: 25, value: 25},
    {key: 30, value: 30}
  ],

  //築年数の選択肢
  buildingAgeLabel: [
    {text: '1年', value: 1}, {text: '3年', value: 3}, {text: '5年', value: 5}, {text: '7年', value: 7},
    {text: '10年', value: 10}, {text: '15年', value: 15}, {text: '20年', value: 20}, {text: '25年', value: 25},
    {text: '30年', value: 30}
  ],

  //住宅ローンの選択肢
  repaymentYears: Array.from({length: 36}, (_, i) => {
    const nensu = i + 1;
    if (nensu === 36) return {key: `${nensu}`, value: `${nensu}年以上`};
    return {key: nensu, value: `${nensu}年`};
  }),

  // 間取り
  layout: [
    {text: "1R", value: 1}, {text: "1K", value: 2}, {text: "1DK", value: 3}, {text: "1LDK", value: 4},
    {text: "2K・2DK", value: 5}, {text: "2LDK", value: 6}, {text: "3K・3DK", value: 7}, {text: "3LDK", value: 8},
    {text: "4K・4DK", value: 9}, {text: "4LDK", value: 10}, {text: "5K・5DK", value: 11}, {text: "5LDK", value: 12},
    {text: "6K・6DK", value: 13}, {text: "6LDK", value: 14}, {text: "6LDK以上", value: 15}
  ],

  //こだわり条件
  commitment: {
    kodate: [
      {text: "リフォーム・リノベーション", value: 1}
    ],
    mansion: [
      {text: "1階", value: 2}, {text: "最上階", value: 3}, {text: "角部屋", value: 4},
      {text: "BSアンテナ", value: 5}, {text: "CSアンテナ", value: 6}, {text: "CATV", value: 7},
      {text: "オートロック", value: 8}, {text: "ルーフバルコニー", value: 9}, {text: "リフォーム・リノベーション", value: 10},
      {text: "低層マンション", value: 11}, {text: "タワーマンション", value: 12}, {text: "ペット相談", value: 13},
      {text: "楽器相談", value: 14},
    ],
    tochi: [
      {text: "角地", value: 15}, {text: "更地", value: 16}, {text: "建築条件なし", value: 17},
    ]
  },

  //物件種別
  bukkenShubetsu: [
    {text: '売地', value: '1101'}, {text: '借地権譲渡', value: '1102'}, {text: '底地権譲渡', value: '1103'},
    {text: '新築戸建', value: '1201'}, {text: '中古戸建', value: '1202'}, {text: '新築テラスハウス', value: '1203'},
    {text: '中古テラスハウス', value: '1204'}, {text: '新築マンション', value: '1301'}, {text: '中古マンション', value: '1302'},
    {text: '新築公団', value: '1303'}, {text: '中古公団', value: '1304'}, {text: '新築公社', value: '1305'},
    {text: '中古公社', value: '1306'}, {text: '新築タウンハウス', value: '1307'}, {text: '中古タウンハウス', value: '1308'},
    {text: 'リゾートマンション', value: '1309'}, {text: '店舗', value: '1401'}, {text: '店舗付住宅', value: '1403'},
    {text: '住宅付店舗', value: '1404'}, {text: '事務所', value: '1405'}, {text: '店舗・事務所', value: '1406'},
    {text: 'ビル', value: '1407'}, {text: '工場', value: '1408'}, {text: 'マンション', value: '1409'},
    {text: '倉庫', value: '1410'}, {text: 'アパート', value: '1411'}, {text: '寮', value: '1412'},
    {text: '旅館', value: '1413'}, {text: 'ホテル', value: '1414'}, {text: '別荘', value: '1415'},
    {text: 'リゾートマンション', value: '1416'}, {text: 'モーテル', value: '1420'}, {text: '医院', value: '1499'},
    {text: 'ガソリンスタンド', value: '1502'}, {text: '特殊浴場', value: '1505'}, {text: 'サウナ', value: '1506'},
    {text: '保養所', value: '1507'}, {text: '作業所', value: '1509'}, {text: '駐車場', value: '1510'},
    {text: 'その他', value: '1599'},
  ],

  shubetsuMansion : 'マンション部屋',

  BUKKEN_SHUBETSU_VALUE : {
    LAND_FOR_SALE: '1101',
    LEASEHOLD_RIGHT: '1102',
    LAND: '1103',
    NEWLY_BUILT_DETACHED_HOUSE: '1201',
    USED_DETACHED_HOUSE: '1202',
    NEW_TERRACED_HOUSE: '1203',
    USED_TERRACED_HOUSE: '1204',
    NEWLY_BUILT_CONDOMINIUM: '1301',
    SECONDHAND_APARTMENT: '1302',
    NEW_CONSTRUCTION_PUBLIC_CORPORATION_1: '1303',
    SECONDHAND_PUBLIC_CORPORATION_1: '1304',
    NEW_CONSTRUCTION_PUBLIC_CORPORATION_2: '1305',
    SECONDHAND_PUBLIC_CORPORATION_2: '1306',
    NEW_TOWNHOUSE: '1307',
    USED_TOWNHOUSE: '1308',
    RESORT_CONDOMINIUM_1: '1309',
    STORE: '1401',
    HOUSE_WITH_STORE: '1403',
    STORE_WITH_HOUSE: '1404',
    OFFICE: '1405',
    STORE_OR_OFFICE: '1406',
    BUILDING: '1407',
    PLANT: '1408',
    APARTMENT_1: '1409',
    WAREHOUSE: '1410',
    APARTMENT_2: '1411',
    DORMITORY: '1412',
    RYOKAN: '1413',
    HOTEL: '1414',
    VILLA: '1415',
    RESORT_CONDOMINIUM_2: '1416',
    MOTEL: '1420',
    CLINIC: '1499',
    GAS_STATION: '1502',
    SPECIAL_BATH: '1505',
    SAUNA: '1506',
    SANATORIUM: '1507',
    WORKING_PLACE: '1509',
    PARKING: '1510',
    OTHERS: '1599',
  },

  //公開ステータス
  statusPropertyOpen: [
    {text: '非公開', value: '1'}, {text: '公開', value: '2'}, {text: '未設定', value: ''}
  ],

  //物件ステータス
  statusProperty: [
    {text: '新規物確中', value: '2'}, {text: '収集後未対応', value: '3'}, {text: '作成中', value: '4'},
    {text: '参考情報（紹介不可）', value: '5'}, {text: '取扱可（商談なし）', value: '10'}, {text: '取扱可（他社商談）', value: '11'},
    {text: '取扱可（自社商談）', value: '12'}, {text: '成約（他社済み）', value: '14'}, {text: '成約（自社済み）', value: '15'},
    {text: '売止', value: '16'}, {text: '査定中', value: '17'}, {text: '非元付（先物）', value: '18'}, {text: '未設定', value: ''}
  ],

  //選択肢の定数
  reg_project: {
    type: [ // 案件種別
      {text: '買い', value: 'b'}, {text: '売り', value: 's'}, {text: 'その他', value: 'e'},
    ],
    status: {
      buy: [
        {text: "将来見込み", value: 100},
        {text: "未対応", value: 200},
        {text: "追客中", value: 300},
        {text: "他決", value: 510},
        {text: "中止", value: 520},
        {text: "保留", value: 530},
        {text: "買付済", value: 550},
        {text: "契約済", value: 600},
        {text: "決済済", value: 700},
      ],
      sell: [
        {text: "将来見込み", value: 100},
        {text: "未対応", value: 200},
        {text: "追客中", value: 300},
        {text: "他社受託", value: 410},
        {text: "自社受託", value: 420},
        {text: "他決", value: 510},
        {text: "中止", value: 520},
        {text: "売止", value: 540},
        {text: "買付済", value: 550},
        {text: "契約済", value: 600},
        {text: "決済済", value: 700},
      ],
      both: [
        {text: "将来見込み", value: 100},
        {text: "未対応", value: 200},
        {text: "追客中", value: 300},
        {text: "他社受託", value: 410},
        {text: "自社受託", value: 420},
        {text: "他決", value: 510},
        {text: "中止", value: 520},
        {text: "保留", value: 530},
        {text: "売止", value: 540},
        {text: "break", value: 'break'},
        {text: "買付済", value: 550},
        {text: "契約済", value: 600},
        {text: "決済済", value: 700},
      ]
    },

    expectation: [
      {text: "0℃", value: 0}, {text: "20℃", value: 20}, {text: "40℃", value: 40},
      {text: "60℃", value: 60}, {text: "80℃", value: 80}, {text: "100℃", value: 100}
    ],

    expectation_buy: [ // 案件温度　買い
      {text: "100℃", value: 100, description: "買付意思確定、書面待ち"}, {text: "80℃", value: 80, description: "買付予定物件あり"}, {text: "60℃", value: 60, description: "前向き検討物あり"},
      {text: "40℃", value: 40, description: "購入意欲高い"}, {text: "20℃", value: 20, description: "購入意欲低い"}, {text: "0℃", value: 0, description: "ひやかし客、ローン不可"}
    ],

    expectation_buy_description:
      "・１００℃ 買付意思確定、書面待ち\n" +
      "・８０℃ 買付予定物件あり\n" +
      "・６０℃ 前向き検討物あり\n" +
      "・４０℃ 購入意欲高い\n" +
      "・２０℃ 購入意欲低い\n" +
      "・０℃ ひやかし客、ローン不可\n" +
      "※案件温度は営業スタッフ目線での温度感を自由に設定いただけます。上記表示されている内容は、温度設定の一例となります。",

    expectation_sell: [ // 案件温度　売り
      {text: "100℃", value: 100, description: "媒介契約手続き中"}, {text: "80℃", value: 80, description: "媒介契約意思確定(口頭承諾)"}, {text: "60℃", value: 60, description: "売却意思確定、会社検討中"},
      {text: "40℃", value: 40, description: "売却意思未確定、意思高い"}, {text: "20℃", value: 20, description: "売却意思未確定、意思低い"}, {text: "0℃", value: 0, description: "価格を知りたいだけ"}
    ],

    expectation_sell_description:
      "・１００℃ 媒介契約手続き中\n" +
      "・８０℃ 媒介契約意思確定(口頭承諾)\n" +
      "・６０℃ 売却意思確定、会社検討中\n" +
      "・４０℃ 売却意思未確定、意思高い\n" +
      "・２０℃ 売却意思未確定、意思低い\n" +
      "・０℃ 価格を知りたいだけ\n" +
      "※案件温度は営業スタッフ目線での温度感を自由に設定いただけます。上記表示されている内容は、温度設定の一例となります。",

    unit: [ // 単位 - 希望面積 // m&#178; &#13217;
      {text: "m²", value: "1"}, {text: "坪", value: "2"},
    ],
    baikai: [ // 媒介
      {text: "一般媒介", value: "1"}, {text: "専任媒介", value: "2"}, {text: "専属専任媒介", value: "3"},
    ],
    timing: { // 購入希望時期
      buy: [
        {text: "すぐにでも購入したい", value: "すぐにでも購入したい"}, {text: "１年以内", value: "１年以内"},
        {text: "３年以内", value: "３年以内"}, {text: "５年以内", value: "５年以内"},
        {text: "時期未定", value: "時期未定"}, {text: "その他", value: "その他"},
      ],
      sell: [ // 売却希望時期
        {text: "すぐにでも売却したい", value: "0"}, {text: "１年以内", value: "1"},
        {text: "３年以内", value: "3"}, {text: "５年以内", value: "5"},
        {text: "時期未定", value: "9"}, {text: "その他", value: "e"},
      ]
    },
    cause: [ // 動機
      {text: "賃貸よりも購入の方が良いと思ったから", value: 1}, {text: "狭い", value: 2},
      {text: "古い", value: 3}, {text: "子供のため", value: 4},
      {text: "通勤、通学に不便", value: 5}, {text: "結婚予定", value: 6},
      {text: "世帯を分けるため", value: 7}, {text: "同居するため", value: 8},
      {text: "その他", value: 9},
    ],
    priority: [ // 優先順位
      {text: "予算", value: '0'}, {text: "地域", value: '1'}, {text: "広さ大きさ", value: '2'},
      {text: "維持費の安さ", value: '3'}, {text: "駅に近い", value: '4'}, {text: "生活施設に近い", value: '5'},
      {text: "築年数が浅い", value: '6'}, {text: "立地条件（日当たりや周辺環境）", value: '7'}, {text: "利回りが良い", value: '8'},
      {text: "部屋数", value: '10'}, {text: "その他", value: 'e'},
    ],
    jigyoType: [ // 事業用種別
      {text: "店舗、事務所", value: '10'}, {text: "店舗、事務所付き住宅", value: '20'}, {text: "一棟ビル", value: '30'},
      {text: "一棟マンション", value: '40'}, {text: "その他", value: '50'},
    ],
    structure: [ // 構造
      {text: "木造", value: 1}, {text: "重量鉄骨", value: 2}, {text: "軽量鉄骨", value: 3},
      {text: "RC", value: 4}, {text: "その他", value: 5},
    ],
    loan: [
      {text: "あり", value: 0}, {text: "なし", value: 1},
    ],
    loanShinsa: [
      {text: "承認済", value: '1'}, {text: "否認", value: '2'},
      {text: "審査中", value: '3'}, {text: "未提出", value: '9'},
    ],
    advertise: [ // 広告活動
      {text: "写真掲載", value: 1}, {text: "自社ホームページ", value: 2},
      {text: "ポータルサイト", value: 3}, {text: "ポスティング", value: 4},
      {text: "物件展示、オープンハウス", value: 5},
    ],
    reason: [ //売却理由
      {text: "転勤", value: "1"}, {text: "住み替え", value: "2"}, {text: "相続", value: "3"},
      {text: "住宅ローンの支払が難しい", value: "4"}, {text: "離婚", value: "5"}, {text: "利用予定がない", value: "6"},
      {text: "資金が必要", value: "7"}, {text: "貸出検討", value: "8"}, {text: "賃貸中（管理家主）", value: "9"},
      {text: "賃貸中（管理家主外）", value: "10"}, {text: "その他", value: "e"},
    ],
    tsudukigara: [ //続柄
      {text: "本人", value: "01"}, {text: "配偶者（夫）", value: "02"}, {text: "配偶者（妻）", value: "03"},
      {text: "子供", value: "04"}, {text: "兄", value: "05"}, {text: "姉", value: "06"},
      {text: "弟", value: "07"}, {text: "妹", value: "08"}, {text: "本人の父", value: "09"},
      {text: "本人の母", value: "10"}, {text: "配偶者の父", value: "11"}, {text: "配偶者の母", value: "12"},
      {text: "その他", value: "e"},
    ],
    participants: [ //物件関係者
      {text: "ご主人", value: 1}, {text: "奥様", value: 2}, {text: "お子様", value: 3},
      {text: "ご両親", value: 4}, {text: "ご親戚", value: 5}, {text: "その他", value: 6}
    ],
    focus: [ // 重視する点
      {text: "会社の信用性", value: '1'},
      {text: "高く売る仕組みがあるか否か", value: '2'},
      {text: "早く売るための仕組みがあるか", value: '3'},
      {text: "不動産売却に関わる相談に対応できる会社（相続、離婚、住宅ローン決済）", value: '4'},
      {text: "ホームページやチラシなど広告を大々的に行ってくれるかどうか", value: '5'},
      {text: "その他", value: 'e'},
    ],
    anxiety: [ //不安材料
      {text: "手元に資金がどのくらい残るか", value: '1'},
      {text: "決定権者全員（親戚）をまとめることができるか", value: '2'},
      {text: "住み替え時の購入を売却の流れ（いつまでに済むことができるか）", value: '3'},
      {text: "売れるのが長引いた場合にどうすればよいか", value: '4'},
      {text: "購入者が内覧する際の対応", value: '5'},
      {text: "その他", value: 'e'},
    ],
    correlation: [ // 相関性
      {text: "住宅ローンの残高以上で売却したい", value: '1'},
      {text: "明確な日程がある", value: '2'},
      {text: "３ヶ月以内で売却できる査定価格での販売にしたい", value: '3'},
      {text: "６ヶ月以上かかってもいいので高めの売却価格で販売したい", value: '4'},
    ],
    documents: [ // 書類
      {text: "建築確認", value: 1}, {text: "検査済証", value: 2}, {text: "建物図面", value: 3},
      {text: "測量図", value: 4}, {text: "重要事項説明書", value: 5}, {text: "売買契約書", value: 6},
      {text: "リフォーム履歴に関わる書類", value: 7}, {text: "マンション管理規約", value: 8}, {text: "物件パンフレット", value: 9},
      {text: "権利証（登記識別情報）", value: 10}, {text: "身分証明書（免許証など）", value: 11}, {text: "その他", value: 12},
    ],
    nasage: [
      {text: "不可", value: '0'},
      {text: "可能", value: '1'},
    ],
  },

  projectAnkenTypes: ['b', 's'],

  //ラベル名
  labelName: {
    projectNumber: '案件番号',
    clientName: '顧客名',
    repShop: '担当店舗',
    repPerson: '担当者',
    projectLabel: '案件種別',
    useApplications: '使用用途',
    projectStatusBuy: '案件ステータス（買い）',
    projectStatusSell: '案件ステータス（売り）',
    projectPurchase: '買付日',
    projectContract: '契約日',
    projectPaid: '決済日',
    projectTemperature: '案件温度',
    matchingMail: 'マッチングメール',
    stepMail: 'ステップメール',
    firstResponse: '初回反響物件',
    echoProperty: '反響物件',
    pastInformationProperty: '過去案内物件',
    desiredPropertyType: '希望物件種別・予算',
    desiredArea: '希望エリア',
    desiredLine: '希望の沿線',
    desiredSchoolDistrict: '希望の学区',
    desiredTotalBudget: '希望総予算',
    desiredSizeLand: '希望の広さ（土地）',
    desiredSizeBuilding: '希望の広さ（建物）',
    desiredFloorPlan: '希望の間取り',
    buildingAge: '築年数',
    desiredParking: '駐車場希望台数',
    desiredPurchaseTime: '購入希望時期',
    motivationBuy: '購入動機',
    priority: '優先順位',
    commitmentConditions: 'こだわり条件',
    desiredYield: '事業用　希望利回り',
    desiredIncome: '事業用　希望収入',
    buildingStructure: '事業用　構造',
    loanUse: 'ローン利用の有無',
    desiredMonthlyRepayment: '月々の返済希望額',
    desiredBonusRepayment: 'ボーナス時返済希望額',
    desiredRepaymentYears: '返済希望年数',
    examinationTime: '事前審査　時期',
    examinationStatus: '事前審査の状況',
    examinationAmount: '事前審査　金額',
    financialInstitution: '事前審査　金融機関',
    denialReason: '事前審査　否認の理由',
    ownResources: '自己資金　金額',
    financialAssistance: '資金援助　金額',
    companyFusion: '社融　金額',
    otherAssistance: 'その他　金額',
    totalResources: '資金調達　合計',
    propertyNumber: '物件番号',
    ownPropertyLocation: '所在地',
    ownPropertyBuildingName: '物件名',
    ownPropertyIssueRoom: '号室',
    ownPropertyType: '種別',
    ownPropertySituation: '取引態様',
    ownPropertyStatus: '物件ステータス',
    ownPropertyAmount: '価格',
    ownPropertyYield: '想定利回り',
    ownPropertyIncome: '年間想定収入',
    ownPropertyLand: '土地面積',
    ownPropertyBuilding: '建物・専有面積',
    priceCut: '値下げ',
    priceReduction: '値下げ幅',
    advertisingActivity: '広告活動の許可',
    appealingPoint: '物件アピールポイント',
    noticeMatters: '告知事項',
    desiredAmountSell: '売却希望金額',
    desiredTimeSell: '売却希望時期',
    desiredReasonSell: '売却理由',
    RegisteredHolder: '登記名義人',
    propertyRelatedPerson: '物件関係者',
    discussion: '関係者との話し合い',
    purchasePriceLand: '当時購入金額（土地）',
    purchasePriceBuilding: '当時購入金額（建物）',
    purchasePriceTotal: '当時購入金額（合計）',
    residualBonds: '残債',
    residualBondsAmount: '残債　金額',
    residualBondsBank: '残債　金融機関',
    ownPropertyDocuments: '所有物件の書類',
    consultationHistory: '他社相談履歴',
    consultationCompany: '他社相談履歴　業者名',
    consultationTime: '他社相談履歴　時期',
    importantPoints: '業者選びで重視している点',
    anxietyMaterial: '不安材料',
    correlation: '期間と価格の相関性',
    assessedAmountPrivate: '自社査定金額',
    assessedAmountOther: '他社査定金額',
    mediationTypePrivate: '媒介種類',
    mediationContractDatePrivate: '媒介契約日',
    mediationUpdateDatePrivate: '媒介更新日',
    mediationContractOtherName: '他社受託　業者名',
    mediationTypeOther: '他社受託　媒介種類',
    mediationContractDateOther: '他社受託　時期',
    remarks: '備考',
    attachmentFile: '添付ファイル',
    clientSupport: 'お客様サポートフラグ',
    baikyakuPropertyInputFormat: '入力形式',
    baikyakuPropertyType: '物件種別',
    baikyakuPropertyArea: '所在地',
    baikyakuPropertyBuilding: '建物名',
    baikyakuPropertyRoomNumber: '号室',
    baikyakuPropertyKingaku: '売却価格',
    baikyakuPropertyTochiMenseki: '土地面積',
    baikyakuPropertyTatemonoMenseki: '建物面積',
    baikyakuPropertySoteiRimawari: '想定利回り',
    baikyakuPropertySoteiShunyu: '年間想定収入',
  },
  projectTypeKey: {
    buy: 'b',
    sell: 's',
    other: 'e',
  },
  projectTypesValue: {
    'b': {name: '買い', name_en: 'buy'},
    's': {name: '売り', name_en: 'sell'},
    'e': {name: 'その他', name_en: 'other'},
  },
  path: {
    basicInformation: [
      'tenpo',
      'main_shop_user',
      'sub_shop_user',
      'anken_type',
      'status',
      'ondo',
      'flag_mm_mail_kai',
      'flag_mm_mail_uri',
      'flag_step_mail',
    ],
    purchaseConditions: [
      'kibou_kodate_new',
      'kibou_kodate_new_kakaku_min',
      'kibou_kodate_new_kakaku_max',
      'kibou_kodate_old',
      'kibou_kodate_old_kakaku_min',
      'kibou_kodate_old_kakaku_max',
      'kibou_mansion_new',
      'kibou_mansion_new_kakaku_min',
      'kibou_mansion_new_kakaku_max',
      'kibou_mansion_old',
      'kibou_mansion_old_kakaku_min',
      'kibou_mansion_old_kakaku_max',
      'kibou_tochi',
      'kibou_tochi_kakaku_min',
      'kibou_tochi_kakaku_max',
      'kibou_jigyo',
      'kibou_jigyo_kakaku_min',
      'kibou_jigyo_kakaku_max',
      'kibou_jigyo_shubetsu',
      'kibou_jigyo_shubetsu_sonota',
      'kozo_sonota_text',
      'desiredConditionKozos',
      'sotei_rimawari_min',
      'sotei_rimawari_max',
      'sotei_syunyu_min',
      'sotei_syunyu_max',
      'tochi_menseki_min',
      'tochi_menseki_max',
      'tochi_menseki_unit',
      'menseki_min',
      'menseki_max',
      'menseki_tani_unit',
      'desiredConditionMadoris',
      'parking_hutu_daisu',
      'parking_kei_daisu',
      'chikunensu_min',
      'chikunensu_max',
      'douki_sonota_text',
      'desiredConditionDoukis',
      'kibou_jiki',
      'kibou_jiki_sonota',
      'priority1',
      'priority2',
      'priority3',
      'priority_sonota',
      'desiredConditionKodawaris',
      'desiredConditionAreas',
    ],
    loanAndFund: [
      'flag_loan',
      'shiharai_tsuki_min',
      'shiharai_tsuki_max',
      'shiharai_bonus_min',
      'shiharai_bonus_max',
      'hensaikibo_y',
      'jizen_jiki',
      'jizen_status',
      'jizen_kingaku',
      'jizen_kinyu',
      'jizen_hinin',
      'jikoshikin_kingaku',
      'enjoshikin_kingaku',
      'syayushikin_kingaku',
      'shikin_sonota_kingaku',
    ],
    forSale: [
      'baikyaku_kingaku_min',
      'baikyaku_kingaku_max',
      'baikyaku_jiki',
      'baikyaku_jiki_sonota',
      'baikyaku_riyu',
      'baikyaku_riyu_sonota',
      'kankeisya_type_sonota',
      'desiredConditionPropertyRelatedPersons',
      'kankeisya_sodan',
      'toji_kingaku_tochi',
      'toji_kingaku_building',
      'toji_kingaku_sum',
      'zanseki_flg',
      'zanseki_kingaku',
      'zanseki_kinyu',
      'document_sonota',
      'desiredConditionOwnPropertyDocuments',
      'tasyasodan_flg',
      'select_point',
      'select_point_sonota',
      'huan_point',
      'huan_point_sonota',
      'kikankakaku_point',
    ],
    propertyToBeSold: [
      'desiredConditionKoukokuKatsudous',
      'nesage_check',
      'nesage_max',
      'appeal_point',
      'kokuchi',
      'baikyaku_property_input_format',
      'baikyaku_property_type',
      'baikyaku_property_type_sonota',
      'baikyaku_property_area',
      'baikyaku_property_building',
      'baikyaku_property_room_number',
      'baikyaku_property_kingaku',
      'baikyaku_property_tochi_menseki',
      'baikyaku_property_tatemono_menseki',
      'baikyaku_property_sotei_rimawari',
      'baikyaku_property_sotei_shunyu',
    ],
    asssessment: [
      'jisya_satei_date',
      'jisya_satei_kingaku',
      'desiredConditionTasyasateis',
      'baikai_type',
      'baikai_keiyaku_date',
      'baikai_koshin_date',
      'desiredConditionTasyajyutakus',
    ],
    matchingConditions: [
      'matchingCondition',
    ],
    attachment: [
      // TODO #986 add path config for 備考・添付ファイル
      'bikou',
    ],
  },
  defaultStaff: 0,
  defaultStaffOption: {key: null, value: '未設定'},

  csvLabels: {
    // 以下、clientRelation（顧客）の内容
    client: {title: '顧客番号'},
    kigyo: {title: '企業番号', isKigyo: true},
    rank_tsuikyaku: {title: '顧客ランク', enum: {type: 'customer', path: 'rank'}},
    main_tantosha_ninmei_date: {title: '新規反響日', type: 'date'},
    company_client: {title: '法人・個人フラグ', enum: {type: 'customer', path: 'companyClient'}},
    flag_uranashi: {title: '裏なしフラグ', enum: {type: 'project', path: 'existence'}},
    sex: {title: '性別', enum: {type: 'customer', path: 'reg_customer.gender'}},
    company_name: {title: '法人名'},
    company_kana: {title: '法人名（カナ）'},
    company_busyo: {title: '部署名'},
    name_last: {title: '姓名（姓）'},
    name_first: {title: '姓名（名）'},
    kana_last: {title: '姓名カナ（姓）'},
    kana_first: {title: '姓名カナ（名）'},
    birthday: {title: '誕生日', type: 'date'},
    tel1: {title: '電話番号１', isParseString: true},
    tel2: {title: '電話番号２', isParseString: true},
    fax: {title: 'FAX番号', isParseString: true},
    mail1: {title: 'メールアドレス１'},
    mail2: {title: 'メールアドレス２'},
    sonota_tel: {title: 'その他番号'},
    mail_kinsi: {title: 'メール配信禁止フラグ', enum: {type: 'project', path: 'existence'}},
    jitakuyuso_kinsi: {title: '郵送禁止フラグ', enum: {type: 'project', path: 'existence'}},
    homon_huka: {title: '訪問禁止フラグ', enum: {type: 'project', path: 'existence'}},
    zip: {title: '連絡先住所：郵便番号', isParseString: true},
    todohuken: {title: '連絡先住所：都道府県', relation: {name: 'todohukenRelation', column: 'todohuken_nm'}},
    sikucyoson: {title: '連絡先住所：市区町村', relation: {name: 'sikucyosonRelation', column: 'sikucyoson_nm'}},
    cyo: {title: '連絡先住所：町', relation: {name: 'cyoRelation', column: 'cyo_nm'}},
    cyomoku: {title: '連絡先住所：町目', relation: {name: 'cyomokuRelation', column: 'cyomoku_nm'}},
    syosai_jusyo: {title: '連絡先住所：詳細住所'},
    tatemono: {title: '連絡先住所：建物名'},
    // location: {title: '連絡先住所：GPS座標'}, TODO 現在は使用していない
    office_zip: {title: '勤務先住所：郵便番号', isParseString: true},
    office_todohuken: {title: '勤務先住所：都道府県', relation: {name: 'officeTodohukenRelation', column: 'todohuken_nm'}},
    office_sikucyoson: {title: '勤務先住所：市区町村', relation: {name: 'officeSikucyosonRelation', column: 'sikucyoson_nm'}},
    office_cyo: {title: '勤務先住所：町', relation: {name: 'officeCyoRelation', column: 'cyo_nm'}},
    office_cyomoku: {title: '勤務先住所：町目', relation: {name: 'officeCyomokuRelation', column: 'cyomoku_nm'}},
    office_banchi_ikou: {title: '勤務先住所：詳細住所'},
    office_tatemono: {title: '勤務先住所：建物名'},
    // office_location: {title: '勤務先住所：GPS座標'}, TODO 現在は使用していない
    person_job: {title: '職業：本人'},
    person_kinmusaki: {title: '勤務先：本人'},
    person_kinzokukunensu: {title: '勤続年数：本人'},
    person_nesyu: {title: '年収：本人'},
    summer1_zokugara: {title: '続柄：合算人１', enum: {type: 'customer', path: 'reg_customer.tsudukigara2'}},
    summer1_zokugara_sonota: {title: '続柄その他：合算人１'},
    summer1_job: {title: '職業：合算人１'},
    summer1_kinmusaki: {title: '勤務先：合算人１'},
    summer1_kinzokukunensu: {title: '勤続年数：合算人１'},
    summer1_nesyu: {title: '年収：合算人１'},
    summer1_gassan_hiritsu: {title: '合算比率：合算人１'},
    summer2_zokugara: {title: '続柄：合算人２', enum: {type: 'customer', path: 'reg_customer.tsudukigara2'}},
    summer2_zokugara_sonota: {title: '続柄その他：合算人２'},
    summer2_job: {title: '職業：合算人２'},
    summer2_kinmusaki: {title: '勤務先：合算人２'},
    summer2_kinzokukunensu: {title: '勤続年数：合算人２'},
    summer2_nesyu: {title: '年収：合算人２'},
    summer3_gassan_hiritsu: {title: '合算比率：合算人２'},
    remark: {title: '備考　顧客情報'},
    cooperate_flg: {title: '連携フラグ', enum: {type: 'customer', path: 'reg_customer.kariire'}},
    genzai_kariire: {title: '現在の借入', enum: {type: 'customer', path: 'reg_customer.kariire'}},
    genzai_kariire_kingaku: {title: '現在の借入：総額'},
    genzai_kariire_kinyukikan: {title: '現在の借入：金融機関'},
    genzai_kariire_shiharai: {title: '現在の借入：月々返済総額'},
    genzai_kariire_biko: {title: '現在の借入：備考'},
    genkyo_yachin: {title: '現在の家賃'},
    genkyo_madori: {title: '現在の間取り', enum: {type: 'customer', path: 'reg_customer.layout'}},
    genkyo_menseki: {title: '現在の住居面積'},
    genkyo_menseki_tani: {title: '現在の住居面積　単位', enum: {type: 'customer', path: 'reg_customer.unit'}},
    teate_umu: {title: '住宅手当', enum: {type: 'customer', path: 'reg_customer.teate'}},
    teate_kingaku: {title: '住宅手当　金額'},
    tsukin_method: {title: '通勤方法', enum: {type: 'customer', path: 'reg_customer.commute'}},
    tsukin_method_sonota: {title: '通勤方法　その他'},
    tsukin_time_m: {title: '通勤時間　分'},
    genkyo_riyu: {title: '現居を借りた理由'},
    genkyo_human: {title: '現居に対する不満'},
    genkyo_kyojyu_start_ym: {title: '現居の居住開始時期', type: 'date'},
    Interest_loan: {title: '住宅ローン興味', enum: {type: 'customer', path: 'reg_customer.interest_loan'}},
    Interest_seminar: {title: 'セミナー参加興味', enum: {type: 'customer', path: 'reg_customer.interest_seminar'}},
    Interest_event: {title: 'イベント参加興味', enum: {type: 'customer', path: 'reg_customer.interest_event'}},
    syokai_information: {title: '紹介者情報'},
    syokai_client: {title: '紹介者　顧客番号'},
    line_user_id: {title: 'LINE　ユーザーID'},
    portal_member: {title: 'SUMITAS全国版サイト会員登録', enum: {type: 'project', path: 'existence'}},
    shop_member: {title: 'SUMITAS加盟店サイト会員登録', enum: {type: 'project', path: 'existence'}},
    deleted_client: {title: '削除フラグ　顧客'},
    created_at_client: {title: '登録日時　顧客', type: 'datetime'},
    created_user_client: {title: '登録者ID　顧客'},
    updated_at_client: {title: '更新日時　顧客', type: 'datetime'},
    updated_user_client: {title: '更新者ID　顧客'},

    // 以下、案件の内容
    kibou: {title: '希望条件番号'},
    tenpo: {title: '担当店舗ID'},
    seiyaku: {title: '成約ID'},
    status: {title: '案件ステータス', enum: {type: 'project', path: 'projectStatus', isInt: true}},
    ondo: {title: '案件温度', enum: {type: 'project', path: 'reg_project.expectation', isInt: true}},
    main_shop_user: {title: '主担当者', relation: {name: 'mainShopUser', column: 'name'}},
    sub_shop_user: {title: '副担当者', relation: {name: 'subShopUser', column: 'name'}},
    flag_mm_mail_uri: {title: 'マッチングメールフラグ：売主', enum: {type: 'project', path: 'existence'}},
    flag_mm_mail_kai: {title: 'マッチングメールフラグ：買主', enum: {type: 'project', path: 'existence'}},
    flag_step_mail: {title: 'ステップメールフラグ', enum: {type: 'project', path: 'existence'}},
    anken_type: {title: '案件種別', enum: {type: 'project', path: 'reg_project.type'}},
    jikoshikin_kingaku: {title: '自己資金　金額'},
    enjoshikin_kingaku: {title: '援助資金　金額'},
    syayushikin_kingaku: {title: '社融資金　金額'},
    shikin_sonota_kingaku: {title: 'その他資金調達　金額'},
    flag_loan: {title: 'ローン利用のフラグ', enum: {type: 'project', path: 'existence'}},
    shiharai_tsuki_min: {title: '月々の支払い希望額　下限'},
    shiharai_tsuki_max: {title: '月々の支払い希望額　上限'},
    shiharai_bonus_min: {title: '月々の支払い希望額　ボーナス　下限'},
    shiharai_bonus_max: {title: '月々の支払い希望額　ボーナス　上限'},
    hensaikibo_y: {title: '返済希望年数'},
    jizen_jiki: {title: '事前審査時期'},
    jizen_status: {title: '事前審査状況', enum: {type: 'project', path: 'reg_project.loanShinsa'}},
    jizen_kingaku: {title: '事前審査金額'},
    jizen_kinyu: {title: '事前審査金融期間'},
    jizen_hinin: {title: '事前審査否認の原因'},
    kibou_kodate_new: {title: '希望種別：新築戸建', enum: {type: 'project', path: 'existence'}},
    kibou_kodate_new_kakaku_min: {title: '希望物件価格　下限：新築戸建'},
    kibou_kodate_new_kakaku_max: {title: '希望物件価格　上限：新築戸建'},
    kibou_kodate_old: {title: '希望種別：中古戸建', enum: {type: 'project', path: 'existence'}},
    kibou_kodate_old_kakaku_min: {title: '希望物件価格　下限：中古戸建'},
    kibou_kodate_old_kakaku_max: {title: '希望物件価格　上限：中古戸建'},
    kibou_mansion_new: {title: '希望種別：新築マンション', enum: {type: 'project', path: 'existence'}},
    kibou_mansion_new_kakaku_min: {title: '希望物件価格　下限：新築マンション'},
    kibou_mansion_new_kakaku_max: {title: '希望物件価格　上限：新築マンション'},
    kibou_mansion_old: {title: '希望種別：中古マンション', enum: {type: 'project', path: 'existence'}},
    kibou_mansion_old_kakaku_min: {title: '希望物件価格　下限：中古マンション'},
    kibou_mansion_old_kakaku_max: {title: '希望物件価格　上限：中古マンション'},
    kibou_tochi: {title: '希望種別：土地', enum: {type: 'project', path: 'existence'}},
    kibou_tochi_kakaku_min: {title: '希望物件価格　下限：土地'},
    kibou_tochi_kakaku_max: {title: '希望物件価格　上限：土地'},
    kibou_jigyo: {title: '希望種別：事業用物件', enum: {type: 'project', path: 'existence'}},
    kibou_jigyo_kakaku_min: {title: '希望物件価格　上限：事業用物件'},
    kibou_jigyo_kakaku_max: {title: '希望物件価格　下限：事業用物件'},
    kibou_jigyo_shubetsu: {title: '希望事業用種別', enum: {type: 'project', path: 'reg_project.jigyoType'}},
    kibou_jigyo_shubetsu_sonota: {title: '希望事業用種別　その他'},
    tochi_menseki_min: {title: '希望土地面積　下限'},
    tochi_menseki_max: {title: '希望土地面積　上限'},
    tochi_menseki_unit: {title: '希望土地面積　単位', enum: {type: 'project', path: 'reg_project.unit'}},
    menseki_min: {title: '希望建物面積　下限'},
    menseki_max: {title: '希望建物面積　上限'},
    menseki_tani_unit: {title: '希望建物面積　単位', enum: {type: 'project', path: 'reg_project.unit'}},
    chikunensu_min: {title: '築年数　下限', enum: {type: 'project', path: 'buildingAgeLabel', typeInput: 'value', isInt: true}},
    chikunensu_max: {title: '築年数　上限', enum: {type: 'project', path: 'buildingAgeLabel', typeInput: 'value', isInt: true}},
    sotei_rimawari_min: {title: '想定利回り　下限'},
    sotei_rimawari_max: {title: '想定利回り　上限'},
    sotei_syunyu_min: {title: '想定収入　下限'},
    sotei_syunyu_max: {title: '想定収入　上限'},
    parking_hutu_daisu: {title: '普通車　駐車場台数'},
    parking_kei_daisu: {title: '軽自動車　駐車場台数'},
    kibou_jiki: {title: '購入希望時期'},
    kibou_jiki_sonota: {title: '購入希望時期　その他'},
    priority1: {title: '優先順位１'},
    priority2: {title: '優先順位２'},
    priority3: {title: '優先順位３'},
    priority_sonota: {title: '優先順位　その他'},
    kozo_sonota_text: {title: '構造　その他'},
    douki_sonota_text: {title: '動機　その他'},
    kodawari_text: {title: 'こだわり条件　テキスト'},
    kako_rireki: {title: '過去反響物件　テキスト'},
    baikyaku_property: {title: '売却物件番号'},
    baikyaku_kingaku_min: {title: '売却希望金額　下限'},
    baikyaku_kingaku_max: {title: '売却希望金額　上限'},
    baikyaku_jiki: {title: '売却希望時期', enum: {type: 'project', path: 'reg_project.timing.sell'}},
    baikyaku_jiki_sonota: {title: '売却希望時期　その他'},
    jisya_satei_kingaku: {title: '自社査定金額'},
    jisya_satei_date: {title: '自社査定時期', type: 'date'},
    toji_kingaku_tochi: {title: '当時購入金額：土地'},
    toji_kingaku_building: {title: '当時購入金額：建物'},
    toji_kingaku_sum: {title: '当時購入金額：合計'},
    zanseki_flg: {title: '残債フラグ', enum: {type: 'project', path: 'existence'}},
    zanseki_kingaku: {title: '残債金額'},
    zanseki_kinyu: {title: '残債金融機関'},
    tasyasodan_flg: {title: '他社相談フラグ', enum: {type: 'project', path: 'existence'}},
    baikyaku_riyu: {title: '売却理由', enum: {type: 'project', path: 'reg_project.reason'}},
    baikyaku_riyu_sonota: {title: '売却理由　その他'},
    kankeisya_type_sonota: {title: '物件関係者　その他'},
    kankeisya_sodan: {title: '物件関係者　相談フラグ', enum: {type: 'project', path: 'discussion'}},
    select_point: {title: '不動産選びで重視している点', enum: {type: 'project', path: 'reg_project.focus'}},
    select_point_sonota: {title: '不動産選びで重視している点　その他'},
    huan_point: {title: '不安材料', enum: {type: 'project', path: 'reg_project.anxiety'}},
    huan_point_sonota: {title: '不安材料　その他'},
    kikankakaku_point: {title: '期間価格', enum: {type: 'project', path: 'reg_project.correlation'}},
    document_sonota: {title: '所有物件の書類　その他'},
    appeal_point: {title: '物件アピールポイント'},
    kokuchi: {title: '告知事項'},
    baikai_type: {title: '自社媒介　種類', enum: {type: 'project', path: 'reg_project.baikai'}},
    baikai_keiyaku_date: {title: '自社媒介　契約日', type: 'date'},
    baikai_koshin_date: {title: '自社媒介　更新期日', type: 'date'},
    nesage_check: {title: '値下げチェック', enum: {type: 'project', path: 'reg_project.nasage'}},
    nesage_max: {title: '値下げ幅'},
    bikou: {title: '備考　案件情報'},
    baitai_id: {title: '反響媒体'},
    property: {title: '反響物件番号'},
    receive_at: {title: '反響日', type: 'datetime'},
    deleted_kibou: {title: '削除フラグ　案件'},
    created_at_kibou: {title: '登録日　案件', type: 'datetime'},
    created_user_kibou: {title: '登録者　案件'},
    updated_at_kibou: {title: '更新日　案件', type: 'datetime'},
    updated_user_kibou: {title: '更新者　案件'},
  },

  excel: {
    buyTemplate: '/project_buy_template.xlsx',
    sellTemplate: '/project_sell_template.xlsx',
  },

  projectCsvLabels: {
    client: {title: '顧客番号'},
    name_last: {title: '姓名（姓）'},
    name_first: {title: '姓名（名）'},
    kana_last: {title: '姓名カナ（姓）'},
    kana_first: {title: '姓名カナ（名）'},
    company_name: {title: '法人名'},
    company_kana: {title: '法人名（カナ）'},
    birthday: {title: '誕生日'},
    main_shop_user: {title: '主担当者ID'},
    sub_shop_user: {title: '副担当者ID'},
    created_at_kibou: {title: '担当任命日'},
    anken_type: {title: '案件種別'},
    status: {title: '案件ステータス'},
    tel1: {title: '携帯電話', isParseString: true},
    tel2: {title: '固定電話', isParseString: true},
    fax: {title: 'FAX番号', isParseString: true},
    mail1: {title: 'メールアドレス１'},
    mail2: {title: 'メールアドレス２'},
    rank_tsuikyaku: {title: '顧客ランク'},
    ondo: {title: '案件温度'},
    zip: {title: '連絡先住所：郵便番号', isParseString: true},
    todohuken: {title: '連絡先住所：都道府県'},
    sikucyoson: {title: '連絡先住所：市区町村'},
    cyo: {title: '連絡先住所：町'},
    cyomoku: {title: '連絡先住所：町目'},
    syosai_jusyo: {title: '連絡先住所：詳細住所'},
    tatemono: {title: '連絡先住所：建物名'},
    baitai_id: {title: '反響媒体'},
    property: {title: '反響物件番号'},
    receive_at: {title: '反響日'},
    mail_kinsi: {title: 'メール配信禁止フラグ'},
    jitakuyuso_kinsi: {title: '郵送禁止フラグ'},
    homon_huka: {title: '訪問禁止フラグ'},
    remark: {title: '営業メモ'},
    bikou: {title: '備考'},
    created_at_client: {title: '顧客登録日'},
  },

  //データなしの表示
  noDataText: '未設定',

  clientSuport: [
    {text: '登録済', value: '1'},
    {text: '未登録', value: '0'},
  ],

  //購入希望条件検索
  isKibouAnd: [
    {label: 'いずれかを含む', value: false},
    {label: '全てを含む', value: true},
  ],

  clientKeyForCsv: [
    'client',
    'kigyo',
    'rank_tsuikyaku',
    'main_tantosha_ninmei_date',
    'company_client',
    'flag_uranashi',
    'sex',
    'company_name',
    'company_kana',
    'company_busyo',
    'name_last',
    'name_first',
    'kana_last',
    'kana_first',
    'birthday',
    'tel1',
    'tel2',
    'fax',
    'mail1',
    'mail2',
    'sonota_tel',
    'mail_kinsi',
    'jitakuyuso_kinsi',
    'homon_huka',
    'zip',
    'todohuken',
    'sikucyoson',
    'cyo',
    'cyomoku',
    'syosai_jusyo',
    'tatemono',
    'office_zip',
    'office_todohuken',
    'office_sikucyoson',
    'office_cyo',
    'office_cyomoku',
    'office_banchi_ikou',
    'office_tatemono',
    'person_job',
    'person_kinmusaki',
    'person_kinzokukunensu',
    'person_nesyu',
    'summer1_zokugara',
    'summer1_zokugara_sonota',
    'summer1_job',
    'summer1_kinmusaki',
    'summer1_kinzokukunensu',
    'summer1_nesyu',
    'summer1_gassan_hiritsu',
    'summer2_zokugara',
    'summer2_zokugara_sonota',
    'summer2_job',
    'summer2_kinmusaki',
    'summer2_kinzokukunensu',
    'summer2_nesyu',
    'summer3_gassan_hiritsu',
    'remark',
    'cooperate_flg',
    'genzai_kariire',
    'genzai_kariire_kingaku',
    'genzai_kariire_kinyukikan',
    'genzai_kariire_shiharai',
    'genzai_kariire_biko',
    'genkyo_yachin',
    'genkyo_madori',
    'genkyo_menseki',
    'genkyo_menseki_tani',
    'teate_umu',
    'teate_kingaku',
    'tsukin_method',
    'tsukin_method_sonota',
    'tsukin_time_m',
    'genkyo_riyu',
    'genkyo_human',
    'genkyo_kyojyu_start_ym',
    'Interest_loan',
    'Interest_seminar',
    'Interest_event',
    'syokai_information',
    'syokai_client',
    'line_user_id',
    'portal_member',
    'shop_member',
    'deleted_client',
    'created_at_client',
    'created_user_client',
    'updated_at_client',
    'updated_user_client',
  ],

  clientRelationKeysForCsv: [
    'todohukenRelation',
    'sikucyosonRelation',
    'cyoRelation',
    'cyomokuRelation',
    'officeTodohukenRelation',
    'officeSikucyosonRelation',
    'officeCyoRelation',
    'officeCyomokuRelation',
  ],

  desireRelationKeysForCsv: [
    'mainShopUser',
    'subShopUser',
  ],

  shopUserKeysForCsv: [
    'main_shop_user',
    'sub_shop_user',
  ],

  addressKeysForCsv: {
    todohuken: [
      'todohuken',
    ],
    sikucyoson: [
      'sikucyoson',
    ],
    cyo: [
      'cyo',
    ],
    cyomoku: [
      'cyomoku',
    ],
    officeTodohuken: [
      'office_todohuken',
    ],
    officeSikucyoson: [
      'office_sikucyoson',
    ],
    officeCyo: [
      'office_cyo',
    ],
    officeCyomoku: [
      'office_cyomoku',
    ],
  },

  removeKeysForCsv: [
    'client', 'kibou', 'seiyaku',
  ],

  notRemoveRelationKeysForCsv: [
    'main_shop_user',
    'sub_shop_user',
  ],

  deleteFlag: [
    {value: '0'},
    {value: '1'},
  ],

  // Skip key for export project Csv type `CSV出力`
  projectCsvSkipKey: [
    'baitai_id',
    'property',
    'receive_at',
  ],

  // 売却対象物件の情報入力欄を追加
  baikyaku: {
    baikyakuPropertyInputFormat: [
      {text: "直接入力", value: "0"}, {text: "物件DBから引用", value: "1"},
    ],
    baikyakuPropertyType: [
      {text: "土地", value: "0"}, {text: "戸建", value: "1"}, {text: "マンション", value: "2"},
      {text: "事業用・収益", value: "3"}, {text: "その他", value: "e"},
    ],
  },

  historyLabels: {
    // 以下、clientRelation（顧客）の内容
    client: {title: '顧客番号'},
    kigyo: {title: '企業番号'},
    rank_tsuikyaku: {title: '顧客ランク', enum: {type: 'customer', path: 'rank'}},
    main_tantosha_ninmei_date: {title: '新規反響日'},
    company_client: {title: '法人・個人フラグ', enum: {type: 'customer', path: 'companyClient'}},
    flag_uranashi: {title: '裏なしフラグ', enum: {type: 'project', path: 'existence'}},
    sex: {title: '性別', enum: {type: 'customer', path: 'reg_customer.gender'}},
    company_name: {title: '法人名'},
    company_kana: {title: '法人名（カナ）'},
    company_busyo: {title: '部署名'},
    name_last: {title: '姓名（姓）'},
    name_first: {title: '姓名（名）'},
    kana_last: {title: '姓名カナ（姓）'},
    kana_first: {title: '姓名カナ（名）'},
    birthday: {title: '誕生日'},
    tel1: {title: '電話番号１', isParseString: true},
    tel2: {title: '電話番号２', isParseString: true},
    fax: {title: 'FAX番号', isParseString: true},
    mail1: {title: 'メールアドレス１'},
    mail2: {title: 'メールアドレス２'},
    sonota_tel: {title: 'その他番号'},
    mail_kinsi: {title: 'メール配信禁止フラグ', enum: {type: 'customer', path: 'restrict.mailKinsi.options'}},
    jitakuyuso_kinsi: {title: '郵送禁止フラグ', enum: {type: 'customer', path: 'restrict.jitakuyusoKinsi.options'}},
    homon_huka: {title: '訪問禁止フラグ', enum: {type: 'customer', path: 'restrict.homonHuka.options'}},
    zip: {title: '連絡先住所：郵便番号', isParseString: true},
    todohuken: {title: '連絡先住所：都道府県', relation: {name: 'todohukenRelation', column: 'todohuken_nm'}},
    sikucyoson: {title: '連絡先住所：市区町村', relation: {name: 'sikucyosonRelation', column: 'sikucyoson_nm'}},
    cyo: {title: '連絡先住所：町', relation: {name: 'cyoRelation', column: 'cyo_nm'}},
    cyomoku: {title: '連絡先住所：町目', relation: {name: 'cyomokuRelation', column: 'cyomoku_nm'}},
    syosai_jusyo: {title: '連絡先住所：詳細住所'},
    tatemono: {title: '連絡先住所：建物名'},
    office_zip: {title: '勤務先住所：郵便番号', isParseString: true},
    office_todohuken: {title: '勤務先住所：都道府県', relation: {name: 'officeTodohukenRelation', column: 'todohuken_nm'}},
    office_sikucyoson: {title: '勤務先住所：市区町村', relation: {name: 'officeSikucyosonRelation', column: 'sikucyoson_nm'}},
    office_cyo: {title: '勤務先住所：町', relation: {name: 'officeCyoRelation', column: 'cyo_nm'}},
    office_cyomoku: {title: '勤務先住所：町目', relation: {name: 'officeCyomokuRelation', column: 'cyomoku_nm'}},
    office_banchi_ikou: {title: '勤務先住所：詳細住所'},
    office_tatemono: {title: '勤務先住所：建物名'},
    person_job: {title: '職業：本人'},
    person_kinmusaki: {title: '勤務先：本人'},
    person_kinzokukunensu: {title: '勤続年数：本人'},
    person_nesyu: {title: '年収：本人'},
    summer1_zokugara: {title: '続柄：合算人１', enum: {type: 'customer', path: 'reg_customer.tsudukigara2'}},
    summer1_zokugara_sonota: {title: '続柄その他：合算人１'},
    summer1_job: {title: '職業：合算人１'},
    summer1_kinmusaki: {title: '勤務先：合算人１'},
    summer1_kinzokukunensu: {title: '勤続年数：合算人１'},
    summer1_nesyu: {title: '年収：合算人１'},
    summer1_gassan_hiritsu: {title: '合算比率：合算人１'},
    summer2_zokugara: {title: '続柄：合算人２', enum: {type: 'customer', path: 'reg_customer.tsudukigara2'}},
    summer2_zokugara_sonota: {title: '続柄その他：合算人２'},
    summer2_job: {title: '職業：合算人２'},
    summer2_kinmusaki: {title: '勤務先：合算人２'},
    summer2_kinzokukunensu: {title: '勤続年数：合算人２'},
    summer2_nesyu: {title: '年収：合算人２'},
    summer3_gassan_hiritsu: {title: '合算比率：合算人２'},
    remark: {title: '備考　顧客情報'},
    cooperate_flg: {title: '連携フラグ', enum: {type: 'customer', path: 'reg_customer.kariire'}},
    genzai_kariire: {title: '現在の借入', enum: {type: 'customer', path: 'reg_customer.kariire'}},
    genzai_kariire_kingaku: {title: '現在の借入：総額'},
    genzai_kariire_kinyukikan: {title: '現在の借入：金融機関'},
    genzai_kariire_shiharai: {title: '現在の借入：月々返済総額'},
    genzai_kariire_biko: {title: '現在の借入：備考'},
    genkyo_yachin: {title: '現在の家賃'},
    genkyo_madori: {title: '現在の間取り', enum: {type: 'customer', path: 'reg_customer.layout'}},
    genkyo_menseki: {title: '現在の住居面積'},
    genkyo_menseki_tani: {title: '現在の住居面積　単位', enum: {type: 'customer', path: 'reg_customer.unit'}},
    teate_umu: {title: '住宅手当', enum: {type: 'customer', path: 'reg_customer.teate'}},
    teate_kingaku: {title: '住宅手当　金額'},
    tsukin_method: {title: '通勤方法', enum: {type: 'customer', path: 'reg_customer.commute'}},
    tsukin_method_sonota: {title: '通勤方法　その他'},
    tsukin_time_m: {title: '通勤時間　分'},
    genkyo_riyu: {title: '現居を借りた理由'},
    genkyo_human: {title: '現居に対する不満'},
    genkyo_kyojyu_start_ym: {title: '現居の居住開始時期'},
    Interest_loan: {title: '住宅ローン興味', enum: {type: 'customer', path: 'reg_customer.interest_loan'}},
    Interest_seminar: {title: 'セミナー参加興味', enum: {type: 'customer', path: 'reg_customer.interest_seminar'}},
    Interest_event: {title: 'イベント参加興味', enum: {type: 'customer', path: 'reg_customer.interest_event'}},
    syokai_information: {title: '紹介者情報'},
    syokai_client: {title: '紹介者　顧客番号'},
    line_user_id: {title: 'LINE　ユーザーID'},
    portal_member: {title: 'SUMITAS全国版サイト会員登録', enum: {type: 'customer', path: 'reg_customer.member'}},
    shop_member: {title: 'SUMITAS加盟店サイト会員登録', enum: {type: 'customer', path: 'reg_customer.member'}},
    deleted_client: {title: '削除フラグ　顧客'},
    created_at_client: {title: '登録日時　顧客'},
    created_user_client: {title: '登録者ID　顧客'},
    updated_at_client: {title: '更新日時　顧客'},
    updated_user_client: {title: '更新者ID　顧客'},

    // 以下、案件の内容
    kibou: {title: '希望条件番号'},
    tenpo: {title: '担当店舗', data: {type: 'shopMapList', key: 'key', value: 'value'}},
    seiyaku: {title: '成約ID'},
    status: {title: '案件ステータス', enum: {type: 'project', path: 'projectStatus'}},
    ondo: {title: '案件温度', enum: {type: 'project', path: 'reg_project.expectation'}},
    main_shop_user: {title: '主担当者', relation: {name: 'mainShopUser', column: 'name'}},
    sub_shop_user: {title: '副担当者', relation: {name: 'subShopUser', column: 'name'}},
    flag_mm_mail_uri: {title: 'マッチングメールフラグ：売主', enum: {type: 'project', path: 'flagMail'}},
    flag_mm_mail_kai: {title: 'マッチングメールフラグ：買主', enum: {type: 'project', path: 'flagMail'}},
    flag_step_mail: {title: 'ステップメールフラグ', enum: {type: 'project', path: 'matchingMail'}},
    anken_type: {title: '案件種別', enum: {type: 'project', path: 'reg_project.type'}},
    jikoshikin_kingaku: {title: '自己資金　金額'},
    enjoshikin_kingaku: {title: '援助資金　金額'},
    syayushikin_kingaku: {title: '社融資金　金額'},
    shikin_sonota_kingaku: {title: 'その他資金調達　金額'},
    flag_loan: {title: 'ローン利用のフラグ', enum: {type: 'project', path: 'existence'}},
    shiharai_tsuki_min: {title: '月々の支払い希望額　下限'},
    shiharai_tsuki_max: {title: '月々の支払い希望額　上限'},
    shiharai_bonus_min: {title: '月々の支払い希望額　ボーナス　下限'},
    shiharai_bonus_max: {title: '月々の支払い希望額　ボーナス　上限'},
    hensaikibo_y: {title: '返済希望年数'},
    jizen_jiki: {title: '事前審査時期'},
    jizen_status: {title: '事前審査状況', enum: {type: 'project', path: 'reg_project.loanShinsa'}},
    jizen_kingaku: {title: '事前審査金額'},
    jizen_kinyu: {title: '事前審査金融期間'},
    jizen_hinin: {title: '事前審査否認の原因'},
    kibou_kodate_new: {title: '希望種別：新築戸建', enum: {type: 'project', path: 'propertyTypeCheck'}},
    kibou_kodate_new_kakaku_min: {title: '希望物件価格　下限：新築戸建'},
    kibou_kodate_new_kakaku_max: {title: '希望物件価格　上限：新築戸建'},
    kibou_kodate_old: {title: '希望種別：中古戸建', enum: {type: 'project', path: 'propertyTypeCheck'}},
    kibou_kodate_old_kakaku_min: {title: '希望物件価格　下限：中古戸建'},
    kibou_kodate_old_kakaku_max: {title: '希望物件価格　上限：中古戸建'},
    kibou_mansion_new: {title: '希望種別：新築マンション', enum: {type: 'project', path: 'propertyTypeCheck'}},
    kibou_mansion_new_kakaku_min: {title: '希望物件価格　下限：新築マンション'},
    kibou_mansion_new_kakaku_max: {title: '希望物件価格　上限：新築マンション'},
    kibou_mansion_old: {title: '希望種別：中古マンション', enum: {type: 'project', path: 'propertyTypeCheck'}},
    kibou_mansion_old_kakaku_min: {title: '希望物件価格　下限：中古マンション'},
    kibou_mansion_old_kakaku_max: {title: '希望物件価格　上限：中古マンション'},
    kibou_tochi: {title: '希望種別：土地', enum: {type: 'project', path: 'propertyTypeCheck'}},
    kibou_tochi_kakaku_min: {title: '希望物件価格　下限：土地'},
    kibou_tochi_kakaku_max: {title: '希望物件価格　上限：土地'},
    kibou_jigyo: {title: '希望種別：事業用物件', enum: {type: 'project', path: 'propertyTypeCheck'}},
    kibou_jigyo_kakaku_min: {title: '希望物件価格　上限：事業用物件'},
    kibou_jigyo_kakaku_max: {title: '希望物件価格　下限：事業用物件'},
    kibou_jigyo_shubetsu: {title: '希望事業用種別', enum: {type: 'project', path: 'reg_project.jigyoType'}},
    kibou_jigyo_shubetsu_sonota: {title: '希望事業用種別　その他'},
    tochi_menseki_min: {title: '希望土地面積　下限'},
    tochi_menseki_max: {title: '希望土地面積　上限'},
    tochi_menseki_unit: {title: '希望土地面積　単位', enum: {type: 'project', path: 'reg_project.unit'}},
    menseki_min: {title: '希望建物面積　下限'},
    menseki_max: {title: '希望建物面積　上限'},
    menseki_tani_unit: {title: '希望建物面積　単位', enum: {type: 'project', path: 'reg_project.unit'}},
    chikunensu_min: {title: '築年数　下限', enum: {type: 'project', path: 'buildingAgeLabel'}},
    chikunensu_max: {title: '築年数　上限', enum: {type: 'project', path: 'buildingAgeLabel'}},
    sotei_rimawari_min: {title: '想定利回り　下限'},
    sotei_rimawari_max: {title: '想定利回り　上限'},
    sotei_syunyu_min: {title: '想定収入　下限'},
    sotei_syunyu_max: {title: '想定収入　上限'},
    parking_hutu_daisu: {title: '普通車　駐車場台数'},
    parking_kei_daisu: {title: '軽自動車　駐車場台数'},
    kibou_jiki: {title: '購入希望時期'},
    kibou_jiki_sonota: {title: '購入希望時期　その他'},
    priority1: {title: '優先順位１'},
    priority2: {title: '優先順位２'},
    priority3: {title: '優先順位３'},
    priority_sonota: {title: '優先順位　その他'},
    kozo_sonota_text: {title: '構造　その他'},
    douki_sonota_text: {title: '動機　その他'},
    kodawari_text: {title: 'こだわり条件　テキスト'},
    kako_rireki: {title: '過去反響物件　テキスト'},
    baikyaku_property: {title: '売却物件番号'},
    baikyaku_kingaku_min: {title: '売却希望金額　下限'},
    baikyaku_kingaku_max: {title: '売却希望金額　上限'},
    baikyaku_jiki: {title: '売却希望時期', enum: {type: 'project', path: 'reg_project.timing.sell'}},
    baikyaku_jiki_sonota: {title: '売却希望時期　その他'},
    jisya_satei_kingaku: {title: '自社査定金額'},
    jisya_satei_date: {title: '自社査定時期'},
    toji_kingaku_tochi: {title: '当時購入金額：土地'},
    toji_kingaku_building: {title: '当時購入金額：建物'},
    toji_kingaku_sum: {title: '当時購入金額：合計'},
    zanseki_flg: {title: '残債フラグ', enum: {type: 'project', path: 'existence'}},
    zanseki_kingaku: {title: '残債金額'},
    zanseki_kinyu: {title: '残債金融機関'},
    tasyasodan_flg: {title: '他社相談フラグ', enum: {type: 'project', path: 'existence'}},
    baikyaku_riyu: {title: '売却理由', enum: {type: 'project', path: 'reg_project.reason'}},
    baikyaku_riyu_sonota: {title: '売却理由　その他'},
    kankeisya_type_sonota: {title: '物件関係者　その他'},
    kankeisya_sodan: {title: '物件関係者　相談フラグ', enum: {type: 'project', path: 'discussion'}},
    select_point: {title: '不動産選びで重視している点', enum: {type: 'project', path: 'reg_project.focus'}},
    select_point_sonota: {title: '不動産選びで重視している点　その他'},
    huan_point: {title: '不安材料', enum: {type: 'project', path: 'reg_project.anxiety'}},
    huan_point_sonota: {title: '不安材料　その他'},
    kikankakaku_point: {title: '期間価格', enum: {type: 'project', path: 'reg_project.correlation'}},
    document_sonota: {title: '所有物件の書類　その他'},
    appeal_point: {title: '物件アピールポイント'},
    kokuchi: {title: '告知事項'},
    baikai_type: {title: '自社媒介　種類', enum: {type: 'project', path: 'reg_project.baikai'}},
    baikai_keiyaku_date: {title: '自社媒介　契約日'},
    baikai_koshin_date: {title: '自社媒介　更新期日'},
    nesage_check: {title: '値下げチェック', enum: {type: 'project', path: 'reg_project.nasage'}},
    nesage_max: {title: '値下げ幅'},
    bikou: {title: '備考　案件情報'},
    deleted_kibou: {title: '削除フラグ　案件'},
    created_at_kibou: {title: '登録日　案件'},
    created_user_kibou: {title: '登録者　案件'},
    updated_at_kibou: {title: '更新日　案件'},
    updated_user_kibou: {title: '更新者　案件'},

    //relations
    desiredConditionKozos	: {title: '事業用　構造'},
    desiredConditionMadoris: {title: '希望の間取り'},
    desiredConditionFiles: {title: '希望条件添付ファイル'},
    desiredConditionTasyajyutakus: {title: '他社受託'},
    desiredConditionTasyasateis: {title: '他社査定'},
    desiredConditionTasyasodans: {title: '他社相談'},
    desiredConditionKodawaris: {title: 'こだわり条件'},
    desiredConditionKoukokuKatsudous: {title: '広告活動の許可'},
    desiredConditionTokimeigis: {title: '登記名義人'},
    desiredConditionDoukis: {title: '購入動機'},
    matchingCondition: {title: 'マッチンング条件'},
    matchingConditionKuzos: {title: 'マッチンング条件 事業用　構造'},
    matchingConditionMadoris: {title: 'マッチンング条件 希望の間取り'},
    matchingConditionAreas: {title: 'マッチンング条件 希望エリア'},
    matchingConditionAreaCyos: {title: 'マッチンング条件 希望地域'},
    matchingConditionAreaGakkous: {title: 'マッチンング条件 希望学区'},
    matchingConditionAreaAreaEkis: {title: 'マッチンング条件 希望沿線・駅'},
    type: {title: '条件設定方法', enum: { path: 'matchingConditions.buy'}},
    desiredConditionAreas: {title: '希望エリア'},
    desiredConditionPropertyRelatedPersons: {title: '物件関係者'},
    desiredConditionOwnPropertyDocuments: {title: '所有物件の書類'},
    desiredConditionAreaCyos: {title: '希望地域'},
    desiredConditionAreaGakkous: {title: '希望学区'},
    desiredConditionAreaEkis: {title: '希望沿線・駅'},
    client_support: {title: 'お客様サポートフラグ', enum: {type: 'project', path: 'client_suport'}},
    baikyaku_property_input_format: {title: '売却対象物件　入力形式', enum: {type: 'project', path: 'baikyaku.baikyakuPropertyInputFormat'}},
    baikyaku_property_type: {title: '売却対象物件　種別', enum: {type: 'project', path: 'baikyaku.baikyakuPropertyType'}},
    baikyaku_property_type_sonota: {title: '売却対象物件　種別その他'},
    baikyaku_property_area: {title: '売却対象物件　所在地'},
    baikyaku_property_building: {title: '売却対象物件　建物名'},
    baikyaku_property_room_number: {title: '売却対象物件　号室'},
    baikyaku_property_kingaku: {title: '売却対象物件　金額'},
    baikyaku_property_tochi_menseki: {title: '売却対象物件　土地面積'},
    baikyaku_property_tatemono_menseki: {title: '売却対象物件　建物面積'},
    baikyaku_property_sotei_rimawari: {title: '売却対象物件　想定利回り'},
    baikyaku_property_sotei_shunyu: {title: '売却対象物件　年間想定収入'},
  },
  skipKey: [
    'created_at',
    'updated_at',
    'createdAt',
    'updatedAt',
    'deleted',
    'updated_user',
    'created_user',
    'kibou',
    'clientRelation',
    'baikyakuProperty',
    'subShopUser',
    'mainShopUser',
    'matchingConditionKozos',
    'kibou',
  ],
  relations: {
    desiredConditionKozos: ['kibou_kozo', 'kozo'],
    desiredConditionMadoris: ['kibou_madori', 'madori'],
    desiredConditionFiles: ['kibou_file', 'file_title', 'file_name'],
    desiredConditionTasyajyutakus: ['desired_conditions_tasyajyutaku', 'baikai_date', 'baikai_type'],
    desiredConditionTasyasateis: ['desired_conditions_tasyasatei', 'satei_kingaku', 'satei_date'],
    desiredConditionTasyasodans: ['desired_conditions_tasyasodan', 'sodan_date'],
    desiredConditionKodawaris: ['kibou_kodawari', 'kodawari'],
    desiredConditionKoukokuKatsudous: ['kibou_koukoku_katsudou', 'koukoku_katsudou'],
    desiredConditionTokimeigis: ['desired_conditions_tokimeigi', 'tokimeigi_name', 'tokimeigi_zokugara'],
    desiredConditionDoukis: ['kibou_douki', 'douki'],
    desiredConditionAreas: [
      'desiredConditionAreaCyos',
      'desiredConditionAreaEkis',
      'desiredConditionAreaGakkous',
      'yusen_no',
      'todohuken',
      'sikucyoson',
      'type',
      'ensen_id',
      'eki_toho',
      'start_eki_id',
      'end_eki_id',
    ],
    desiredConditionAreaCyos: ['todohuken', 'sikucyoson', 'cyo',],
    desiredConditionAreaEkis: ['eki_id'],
    desiredConditionAreaGakkous: ['surrounding'],
    matchingConditionAreas: [
      'yusen_no',
      'todohuken',
      'sikucyoson',
      'type',
      'ensen_id',
      'eki_toho',
      'start_eki_id',
      'end_eki_id',
    ],
    matchingConditionAreaCyos: ['todohuken', 'sikucyoson', 'cyo'],
    matchingConditionAreaGakkous: ['surrounding'],
    matchingConditionAreaAreaEkis: ['eki_id'],
    matchingConditionMadoris: ['madori'],
  },
  client_suport: [
    {text: '未設定', value: '0'},
    {text: '登録済', value: '1'},
  ],
  matchingMail: [
    {text: '未設定', value: '0'},
    {text: '受け取る', value: '1'},
  ],
  flagMail: [
    {text: '未設定', value: null},
    {text: '受け取る', value: '1'},
  ],
  propertyTypeCheck: [
    {text: '未設定', value: '0'},
    {text: '設定', value: '1'},
  ],
  projectBuyKey: [
    'kibou_kodate_new',
    'kibou_kodate_new_kakaku_min',
    'kibou_kodate_new_kakaku_max',
    'kibou_kodate_old',
    'kibou_kodate_old_kakaku_min',
    'kibou_kodate_old_kakaku_max',
    'kibou_mansion_new',
    'kibou_mansion_new_kakaku_min',
    'kibou_mansion_new_kakaku_max',
    'kibou_mansion_old',
    'kibou_mansion_old_kakaku_min',
    'kibou_mansion_old_kakaku_max',
    'kibou_tochi',
    'kibou_tochi_kakaku_min',
    'kibou_tochi_kakaku_max',
    'kibou_jigyo',
    'kibou_jigyo_kakaku_min',
    'kibou_jigyo_kakaku_max',
  ],
  defaultSortTarget: 'kibou',
  sortTarget: [
    {
      key: 'kibou',
      value: '案件番号'
    },
    {
      key: 'desired_conditions.created_at',
      value: '案件日時'
    },
    {
      key: 'desired_conditions.ondo',
      value: '案件温度'
    }
  ],
  defaultRelations: {
    desiredConditionTokimeigis: [],
    desiredConditionTasyasodans: [],
    desiredConditionTasyasateis: [],
    desiredConditionTasyajyutakus: [],
    desiredConditionAreas: [],
    matchingCondition: {
      matchingConditionAreas: [],
    },
    desiredConditionFiles: [],
    desiredConditionKozos: [],
    desiredConditionMadoris: [],
    desiredConditionDoukis: [],
    desiredConditionKodawaris: [],
    desiredConditionPropertyRelatedPersons: [],
    desiredConditionOwnPropertyDocuments: [],
  },
}
