module.exports = {
  types: [
    {text: '店舗利用（店舗共通テンプレート）', value: '1'},
    {text: '個人利用（自身専用テンプレート）', value: '2'}
  ],
  path: {
    basicInformation: [
      'name',
      'type',
      'title',
      'body',
    ],
  },
  pagination: {initPage: 1, pageSize: 50},
  individual: '2',
  defaultSender: 0,
}
