module.exports = {
  // 公開設定
  releaseTypes: [
    {text: '下書き', value: '1'},
    {text: '公開中', value: '2'},
    {text: '公開予定', value: '3'},
    {text: '公開終了', value: '4'},
  ],
  importantFlg: {
    true: 1,
    false: 0,
  },
  importantText: '重要なお知らせ',
  releaseStatus: {
    draft: {class: 'draft', text: '下書き',},
    open: {class: 'open', text: '公開中',},
    coming: {class: 'coming', text: '公開予定',},
    endPublic: {class: 'end', text: '公開終了',},
  },
  draft: '1',
  release: '2',
  dashboardPageSize: 50,
  pagination: {initPage: 1, pageSize: 50,},
  path: {
    basicInformation: [
      'title',
      'body',
      'important_flg',
      'release_type',
      'release_start_date',
      'release_end_date',
    ],
  },
  publicStatus: [
    {text: '下書きとして保存', value: '1'},
    {text: '公開', value: '2'},
  ],
  noDataText: '未設定',
}
