module.exports = {
  // レポート種別
  typeMaps: {
    'b': {name: '買い', name_en: 'buy'},
    's': {name: '売り', name_en: 'sell'},
  },
  types: [
    {text: '買い', value: 'b'},
    {text: '売り', value: 's'},
  ],
  stepTypes: [
    {text: '反響', value: '0'},
    {text: 'アクション', value: '1'},
    {text: '買付／契約／決済', value: '2'},
  ],
  buyAction: [
    // issue#4161 一時非表示に変更
    // {text: 'ポスティング', value: '00'},
    {text: '現地案内', value: '01'},
    {text: '電話（非通電）', value: '02'},
    {text: '電話（通電）', value: '03'},
    {text: '来店面談', value: '04'},
    {text: '訪問面談', value: '05'},
    {text: 'メール', value: '06'},
    {text: 'LINE', value: '07'},
    {text: 'SNS', value: '08'},
    {text: 'FAX', value: '09'},
    {text: '資料投函', value: '10'},
    {text: '資料郵送', value: '11'},
    {text: 'オンライン面談', value: '12'},
    {text: 'OH・イベント来場', value: '13'},
    {text: 'その他（対面）', value: '16'},
    {text: 'その他（非対面）', value: '17'},
  ],
  saleAction: [
    // issue#4161 一時非表示に変更
    // {text: 'ポスティング', value: '00'},
    {text: '電話（非通電）', value: '02'},
    {text: '電話（通電）', value: '03'},
    {text: '来店面談', value: '04'},
    {text: '訪問面談', value: '05'},
    {text: 'メール', value: '06'},
    {text: 'LINE', value: '07'},
    {text: 'SNS', value: '08'},
    {text: 'FAX', value: '09'},
    {text: '資料投函', value: '10'},
    {text: '資料郵送', value: '11'},
    {text: 'オンライン面談', value: '12'},
    {text: 'OH・イベント来場', value: '13'},
    {text: '査定提示（対面）', value: '14'},
    {text: '査定提示（非対面）', value: '15'},
    {text: 'その他（対面）', value: '16'},
    {text: 'その他（非対面）', value: '17'},
  ],
  countCondition: [
    {text: '最初のレコード', value: '0'},
    {text: '2番目以降のレコード', value: '1'},
    {text: '全てのレコード', value: '2'},
  ],
  achievementType: {
    buy: 'b',
    sale: 's',
  },
  achievementStepType: {
    reaction: '0',
    saleActivity: '1',
    transaction: '2',
  },
  buyTransaction: [
    {text: '買付', value: '0'},
    {text: '契約', value: '1'},
    {text: '決済', value: '2'},
  ],
  saleTransaction: [
    {text: '媒介受託', value: '3'},
    {text: '契約', value: '1'},
    {text: '決済', value: '2'},
  ],
  closing: [
    {text: '買付', value: '0'},
    {text: '契約', value: '1'},
    {text: '決済', value: '2'},
    {text: '媒介受託', value: '3'},
  ],
  path: {
    basicInformation: [
      'achievement_name',
      'achievement_type'
    ],
    achievementSteps: [
      'achievementSteps'
    ]
  },
  presence: '1',
  pagination: {initPage: 1, pageSize: 50,}
  ,
}
