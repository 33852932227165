module.exports = {
  phone: /^(\d{3,11})$/,
  email: /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,9})+$/,
  fax: /^(\d{3,11})$/,
  zipCode: /^(\d{7})$/,
  katakana: /^[ァアィイゥウェエォオカガキギクグケゲコゴサザシジスズセゼソゾタダチヂッツヅテデトドナニヌネノハバパヒビピフブプヘベペホボポマミムメモャヤュユョヨラリルレロヮワヰヱヲンヴヵヶヷヸヹヺ・゛゜ーヽヾㇰㇱㇲㇳㇴㇵㇶㇷㇸㇹㇺㇻㇼㇽㇾㇿ㋐㋑㋒㋓㋔㋕㋖㋗㋘㋙㋚㋛㋜㋝㋞㋟㋠㋡㋢㋣㋤㋥㋦㋧㋨㋩㋪㋫㋬㋭㋮㋯㋰㋱㋲㋳㋴㋵㋶㋷㋸㋹㋺㋻㋼㋽㋾ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺabcdefghijklmnopqrstuvwxyz 　]*$/,
  dateString: /^(20\d{2}|19\d{2})\/(1[0-2]|[1-9])$/,
  imgExtension: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
  videoExtension: /\.(avi|mp4|mpg|mkv)$/i,
};
