module.exports = {

  //ページネーション
  pagination: {initPage: 1, pageSize: 50, pageSize20: 20},
  noDataText: '未設定',
  defaultSender: 0,
  stepMailStatus: {
    stop: '0',
    using: '1',
  },
  trackingFlg: {
    unNotice: 0,
    notice: 1
  },
  receiveOpenNotificationMailFlg: {
    unNotice: 0,
    notice: 1
  },
  buyPropertySearchTypes: [
    {text: 'or検索', value: '1'},
    {text: '&検索', value: '2'},
  ],
  buyPropertyRegistrationTypes: [
    {text: 'or登録', value: '1'},
    {text: '&登録', value: '2'},
  ],
  buyPropertySearchTypeEn: {
    orSearch: '1',
    andSearch: '2',
  },
  status: [
    {text: '停止中', value: '0'},
    {text: '利用中', value: '1'}
  ],
  types: [
    {text: '買い', value: '0'},
    {text: '売り', value: '1'},
    {text: '買い・売り', value: '2'},
  ],
  typeEn: {
    buy: '0',
    sell: '1',
    buyAndSell: '2',
  },
  //顧客ランク
  clientRanks: [
    {text: 'A', value: 1}, {text: 'B', value: 2}, {text: 'C', value: 3}, {text: 'D', value: 4},
    {text: 'E', value: 5}, {text: 'F', value: 6}, {text: 'G', value: 7},
  ],
  ankenStatus: [
    {text: "将来見込み", value: 1},
    {text: "未対応", value: 2},
    {text: "追客中", value: 3},
    {text: "他社受託", value: 4},
    {text: "自社受託", value: 5},
    {text: "売止", value: 6},
    {text: "他決", value: 7},
    {text: "中止", value: 8},
    {text: "保留", value: 9},
    {text: "break", value: 'break'},
    {text: "買付済", value: 10},
    {text: "契約済", value: 11},
    {text: "決済済", value: 12},
  ],
  matterStatus: {
    buy: [
      {text: "将来見込み", value: 1},
      {text: "未対応", value: 2},
      {text: "追客中", value: 3},
      {text: "他決", value: 7},
      {text: "中止", value: 8},
      {text: "保留", value: 9},
      {text: "買付済", value: 10},
      {text: "契約済", value: 11},
      {text: "決済済", value: 12},
    ],
    sell: [
      {text: "将来見込み", value: 1},
      {text: "未対応", value: 2},
      {text: "追客中", value: 3},
      {text: "他社受託", value: 4},
      {text: "自社受託", value: 5},
      {text: "売止", value: 6},
      {text: "他決", value: 7},
      {text: "中止", value: 8},
      {text: "買付済", value: 10},
      {text: "契約済", value: 11},
      {text: "決済済", value: 12},
    ],
    both: [
      {text: "将来見込み", value: 1},
      {text: "未対応", value: 2},
      {text: "追客中", value: 3},
      {text: "他社受託", value: 4},
      {text: "自社受託", value: 5},
      {text: "売止", value: 6},
      {text: "他決", value: 7},
      {text: "中止", value: 8},
      {text: "保留", value: 9},
      {text: "買付済", value: 10},
      {text: "契約済", value: 11},
      {text: "決済済", value: 12},
    ],
  },
  ankenOndos: [
    {text: "100℃", value: 1}, {text: "80℃", value: 2}, {text: "60℃", value: 3},
    {text: "40℃", value: 4}, {text: "20℃", value: 5}, {text: "0℃", value: 6}
  ],
  buyPropertyTypes: [
    {text: "新築戸建", value: 1},
    {text: "中古戸建", value: 2},
    {text: "新築マンション", value: 3},
    {text: "中古マンション", value: 4},
    {text: "土地", value: 5},
    {text: "事業用", value: 6},
  ],
  sellReasons: [
    {text: "転勤", value: 1},
    {text: "住み替え", value: 2},
    {text: "相続", value: 3},
    {text: "住宅ローンの支払いが難しい", value: 4},
    {text: "離婚", value: 5},
    {text: "利用予定が無い", value: 6},
    {text: "資金が必要", value: 7},
    {text: "貸出検討", value: 8},
    {text: "賃貸中（管理家主）", value: 9},
    {text: "賃貸中（管理家主外）", value: 10},
    {text: "その他", value: 11},
  ],
  path: {
    basicInformation: [
      'name',
      'status',
      'send_timing',
      'send_time',
      'type',
      'stepMailSettingClientRanks',
      'stepMailSettingAnkenStatuses',
      'stepMailSettingAnkenOndos',
      'stepMailSettingBuyPropertyTypes',
      'title',
      'body',
    ]
  },
  deleteKeyList: [
    '__typename',
    'stepMailFiles',
    'stepMailSettingAnkenOndos',
    'stepMailSettingAnkenStatuses',
    'stepMailSettingBuyPropertyTypes',
    'stepMailSettingClientRanks',
    'stepMailSellReasons',
  ],
}

