module.exports = {
  mailSendFlg: {
    '1': {name: '下書き', name_en: 'draft'},
    '2': {name: '送信予約', name_en: 'reserve'},
    '3': {name: '送信済み', name_en: 'done'},
  },
  //ページネーション
  pagination: {initPage: 1, pageSize: 20,},
  defaultType: '02',
  defaultSender: 0,
  defaultStaffOption: {key: null, value: '未設定'},
  deliveryDate: '送信日時',
  scheduledDate: '予定日時',
  confirmation: '内容確認',
  modify: '変更',
  notChange: '変更不可',
  send: '送信中',
  draft: '1',
  reserve: '2',
  processing: '2',
  done: '3',
  noDataText: '未設定',
}
