module.exports = {
  publicFileDomain: () => {
    switch (process.env.NODE_ENV) {
      case 'master':
        return 'tsunagu-cc.com';
      case 'staging':
        return 'stg.tsunagu-cc.com';
      case 'develop':
      case 'local':
        return 'dev.tsunagu-cc.com';
      default:
    }
  },
};
