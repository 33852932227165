module.exports = {

  //ページネーション
  pagination: {initPage: 1, pageSize: 50, pageSize20: 20},
  noDataText: '未設定',
  path: {
    basicInformation: [
      'file',
    ]
  },
  rowProcess: {
    unprocessed: '0',
    processing: '1',
    success: '2',
    error: '3',
  },
  processTitle: {
    processing: '取り込み中...',
    completed: '取り込み完了',
  },
}

