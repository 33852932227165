module.exports = {
  // Kind of user
  user_kind: {
    headquarter: '0',
    representative: '1',
    storeStaff: '2',
    agent: '3',
  },
  moderator: 1,
}
