module.exports = {
  templateType: {
    'b': '基本',
    's': '店舗',
    'u': '個人',
  },
  pagination: {initPage: 1, pageSize: 20,},
  noDataText: '未設定',
  btnName: {
    create: '追加',
    update: '更新',
    copy: 'コピーを作成',
  },
  titleUpdate: 'テンプレート変更',
  titleCopy: 'テンプレートのコピー',
  titleCreate: 'テンプレート追加',
  basicType: 'b',
  storeType: 's',
  individualType: 'u',
}
