module.exports = {
  fileType: {
    '0': 'Excel',
    '1': 'PDF',
    '2': 'jpg',
  },
  status: {
    newRegister: 0,
    beginExport: 1,
    inProgress: 2,
    complete: 3,
    completeWithError: 9,
  },
  pagination: {initPage: 1, pageSize: 20,},
  templateMode: {
    basic: '0',
    other: '1',
  },
  fileFormat: {
    excel: '0',
    pdf: '1',
    jpeg: '2',
  },
  completeText: '完了',
  creatingText: '生成中',
  saveDefault: '1',
}
