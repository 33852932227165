module.exports = {
  // 更新に不要なプロパティ
  deleteKeyList: [
    'todohukenRelation', 'sikucyosonRelation', 'cyoRelation', 'cyomokuRelation',
    'officeTodohukenRelation', 'officeSikucyosonRelation', 'officeCyoRelation', 'officeCyomokuRelation',
    'syokaiClientRelation', 'location', 'office_location', 'inquiries', 'clientHistories',
    'desiredConditions', '__typename', 'newTag', 'clientReceiveAddresses',
  ],

  deleteTagKeyList: [
    'tag1',
    'tag2',
    'tag3',
    'tag4',
    'tag5',
  ],

  //顧客選択モーダルの顧客表示数
  customerListCount: 20,

  //ありなしチェック
  presence: '1',
  absence: '0',

  //その他のチェック
  isSonota: 'e',
  isSonotaZokugara: '99',

  //ページネーション
  pagination: {initPage: 1, pageSize: 50, pageSize20: 20},

  //顧客ランク
  rank: [
    {text: 'A', value: '0'}, {text: 'B', value: '1'}, {text: 'C', value: '2'}, {text: 'D', value: '3'},
    {text: 'E', value: '4'}, {text: 'F', value: '5'}, {text: 'G', value: '6'},
  ],

  //個人・法人
  companyClient: [{text: '個人', value: '0'}, {text: '法人', value: '1'}],

  //裏なしチェック
  uranashi: [
    {text: '裏なし', value: '1'},
    {text: '裏なし以外', value: '0'},
  ],

  //加盟店状況
  register: [{text: '全国', value: '0',}, {text: '店舗', value: '1',}],
  portalMember: '全国',
  shopMember: '店',

  //連絡手段
  restrict: {
    mailKinsi: {
      text: 'メール配信',
      options: [
        {label: 'OK', value: '0'},
        {label: 'NG', value: '1'},
      ],
    },
    jitakuyusoKinsi: {
      text: '郵送',
      options: [
        {label: 'OK', value: '0'},
        {label: 'NG', value: '1'},
      ],
    },
    homonHuka: {
      text: '訪問',
      options: [
        {label: 'OK', value: '0'},
        {label: 'NG', value: '1'},
      ],
    },
  },
  jitakuyusoKinsi: '郵送禁止',
  mailKinsi: 'メール配信禁止',
  homonHuka: '訪問不可',

  //面積の単位
  areaUnit: {
    squareMetre: '1',
    tsubo: '2',
  },

  //個人・法人チェック
  isKojinValue: '0',
  isHojinValue: '1',

  //ラベル名
  labelName: {
    customerNumber: '顧客番号',
    noBack: '裏なし',
    customerLank: '顧客ランク',
    privateOrCorporation: '個人か法人か',
    tag: 'タグ',
    corporationName: '法人名',
    corporationNameKana: '法人名（カナ）',
    departmentName: '担当者名・部署名',
    lastName: '姓名（姓）',
    firstName: '姓名（名）',
    lastNameKana: '姓名カナ（姓）',
    firstNameKana: '姓名カナ（名）',
    birthday: '生年月日',
    companyBirthday: '創立日',
    age: '年齢',
    companyAge: '創立',
    gender: '性別',
    primarySchool: '小学校',
    juniorHighSchool: '中学校',
    mail: 'メールアドレス',
    mail1: 'メールアドレス１',
    mail2: 'メールアドレス２',
    tel: '電話番号',
    telFaxMail: 'tel/fax/mail',
    telMail: 'tel/mail',
    contactAddress: '連絡先住所',
    workAddress: '勤務先住所',
    contactConditions: '配信・送付・訪問条件',
    cohabitationComposition: '同居人構成',
    cohabitationRelationship: '続柄',
    job: '職業',
    workplace: '勤務先',
    lengthOfService: '勤続年数',
    annualIncome: '年収',
    totalRatio: '合算比率',
    totalAnnualIncome: '合算可能年収',
    totalIncome: '合算年収',
    borrowing: '借り入れの有無',
    totalBorrowing: '借り入れ総額',
    totalMonthlyRepayment: '月々の返済総額',
    bank: '金融機関',
    kariireBiko: '借り入れ内容',
    remarks: '備考',
    currentRent: '現在の家賃',
    floorPlan: '現在の間取り',
    livingArea: '居住面積',
    dateOfResidence: '居住開始日',
    housingAllowance: '住宅手当',
    howToCommute: '通勤方法',
    commutingTime: '通勤時間',
    reasonForRenting: '現居を借りた理由',
    dissatisfaction: '現居に対する不満',
    LoanInterest: 'ローン借入興味',
    seminarInterest: 'セミナー参加興味',
    eventInterest: 'イベント参加興味',
    introducer: '紹介者',
    salesMemo: '営業メモ',
    changeLog: '更新履歴',
  },

  reg_customer: {
    rank: [ // 顧客ランク
      {text: "A", value: "0"}, {text: "B", value: "1"}, {text: "C", value: "2"},
      {text: "D", value: "3"}, {text: "E", value: "4"}, {text: "F", value: "5"}, {text: "G", value: "6"}
    ],
    gender: [ // 性別
      {text: "男性", value: '0'}, {text: "女性", value: '1'}, {text: "その他", value: '2'},
    ],
    tsudukigara: [ //続柄
      {text: "配偶者(夫)", value: "00"}, {text: "配偶者(妻)", value: "01"}, {text: "子供", value: "02"},
      {text: "兄", value: "03"}, {text: "姉", value: "04"}, {text: "弟", value: "05"}, {text: "妹", value: "06"},
      {text: "本人の父", value: "07"}, {text: "本人の母", value: "08"}, {text: "配偶者の父", value: "09"},
      {text: "配偶者の母", value: "10"}, {text: "その他", value: "99"},
    ],
    tsudukigara2: [ //続柄
      {text: "配偶者", value: "1"}, {text: "子供", value: "2"}, {text: "父", value: "3"},
      {text: "母", value: "4"}, {text: "その他", value: "e"},
    ],
    kariire: [ // 借り入れの有無
      {text: "あり", value: '1'}, {text: "なし", value: '0'},
    ],
    teate: [ // 手当の有無
      {text: "あり", value: '1'}, {text: "なし", value: '0'},
    ],
    interest_loan: [ // ローン興味
      {text: "あり", value: '1'}, {text: "なし", value: '0'},
    ],
    interest_seminar: [ // セミナー参加
      {text: "あり", value: '1'},
      {text: "なし", value: '0'},
    ],
    interest_event: [ // イベント参加
      {text: "あり", value: '1'}, {text: "なし", value: '0'},
    ],
    terms: [ // 禁止事項など
      {text: "メール配信禁止", value: 'mail_kinsi',},
      {text: "郵送禁止", value: 'jitakuyuso_kinsi',},
      {text: "訪問不可", value: 'homon_huka',},
    ],
    layout: [ // 間取り
      {text: "1R", value: '0'}, {text: "1K", value: '1'}, {text: "1DK", value: '2'},
      {text: "1LDK", value: '3'}, {text: "2K・2DK", value: '4'}, {text: "2LDK", value: '5'},
      {text: "3K・3DK", value: '6'}, {text: "3LDK", value: '7'}, {text: "4K・4DK", value: '8'},
      {text: "4LDK", value: '9'}, {text: "5K・5DK", value: '10'}, {text: "5LDK", value: '11'},
      {text: "6K・6DK", value: '12'}, {text: "6LDK", value: '13'}, {text: "6LDK以上", value: '14'}
    ],
    unit: [ // 単位 - 居住面積m&#178; &#13217;
      {text: "m²", value: '1'}, {text: "坪", value: '2'},
    ],
    commute: [ //通勤方法
      {text: "車", value: '1'}, {text: "電車", value: '2'}, {text: "バス", value: '3'},
      {text: "徒歩", value: '4'}, {text: "自転車", value: '5'}, {text: "バイク", value: '6'},
      {text: "その他", value: 'e'}
    ],
    member: [
      {text: "登録済", value: '1'}, {text: "未登録", value: '0'},
    ],
  },

  defaultTags: [
    {tag_id: null},
    {tag_id: null},
    {tag_id: null},
    {tag_id: null},
    {tag_id: null},
  ],
  tagsMapRelation: [
    'tag1Master',
    'tag2Master',
    'tag3Master',
    'tag4Master',
    'tag5Master',
  ],

  path: {
    basicInformation: [
      'flag_uranashi',
      'rank_tsuikyaku',
      'company_client',
      'company_name',
      'company_kana',
      'company_busyo',
      'name_last',
      'name_first',
      'kana_last',
      'kana_first',
      'birthday',
      'sex',
      'tel1',
      'tel2',
      'sonota_tel1',
      'fax',
      'mail1',
      'mail2',
      'zip',
      'syosai_jusyo',
      'tatemono',
      'office_zip',
      'office_banchi_ikou',
      'office_tatemono',
      'mail_kinsi',
      'jitakuyuso_kinsi',
      'homon_huka',
    ],
    clientHousemates: [
      'clientHousemates',
    ],
    occupation: [
      'person_job',
      'person_kinmusaki',
      'person_kinzokukunensu',
      'person_nesyu',
      'summer1_zokugara',
      'summer1_zokugara_sonota',
      'summer1_job',
      'summer1_kinmusaki',
      'summer1_kinzokukunensu',
      'summer1_nesyu',
      'summer1_gassan_hiritsu',
      'summer2_zokugara',
      'summer2_zokugara_sonota',
      'summer2_job',
      'summer2_kinmusaki',
      'summer2_kinzokukunensu',
      'summer2_nesyu',
      'summer3_gassan_hiritsu',
    ],
    borrowing: [
      'genzai_kariire',
      'genzai_kariire_kingaku',
      'genzai_kariire_shiharai',
      'genzai_kariire_kinyukikan',
      'genzai_kariire_biko',
    ],
    residence: [
      'genkyo_yachin',
      'genkyo_madori',
      'genkyo_menseki',
      'genkyo_menseki_tani',
      'genkyo_kyojyu_start_ym',
      'teate_umu',
      'teate_kingaku',
      'tsukin_method',
      'tsukin_method_sonota',
      'tsukin_time_m',
      'genkyo_riyu',
      'genkyo_human',
    ],
    businessMemo: [
      'Interest_loan',
      'Interest_seminar',
      'Interest_event',
      'remark',
    ],
  },
  historyLabels: {
    // 以下、clientRelation（顧客）の内容
    client: {title: '顧客番号'},
    kigyo: {title: '企業番号'},
    rank_tsuikyaku: {title: '顧客ランク', enum: {type: 'customer', path: 'rank'}},
    main_tantosha_ninmei_date: {title: '新規反響日'},
    company_client: {title: '法人・個人フラグ', enum: {type: 'customer', path: 'companyClient'}},
    flag_uranashi: {title: '裏なしフラグ', enum: {type: 'project', path: 'existence'}},
    sex: {title: '性別', enum: {type: 'customer', path: 'reg_customer.gender'}},
    company_name: {title: '法人名'},
    company_kana: {title: '法人名（カナ）'},
    company_busyo: {title: '部署名'},
    name_last: {title: '姓名（姓）'},
    name_first: {title: '姓名（名）'},
    kana_last: {title: '姓名カナ（姓）'},
    kana_first: {title: '姓名カナ（名）'},
    birthday: {title: '誕生日'},
    tel1: {title: '電話番号１', isParseString: true},
    tel2: {title: '電話番号２', isParseString: true},
    fax: {title: 'FAX番号', isParseString: true},
    mail1: {title: 'メールアドレス１'},
    mail2: {title: 'メールアドレス２'},
    sonota_tel: {title: 'その他番号'},
    mail_kinsi: {title: 'メール配信禁止フラグ', enum: {type: 'project', path: 'existence'}},
    jitakuyuso_kinsi: {title: '郵送禁止フラグ', enum: {type: 'project', path: 'existence'}},
    homon_huka: {title: '訪問禁止フラグ', enum: {type: 'project', path: 'existence'}},
    zip: {title: '連絡先住所：郵便番号', isParseString: true},
    todohuken: {title: '連絡先住所：都道府県', relation: {name: 'todohukenRelation', column: 'todohuken_nm'}},
    sikucyoson: {title: '連絡先住所：市区町村', relation: {name: 'sikucyosonRelation', column: 'sikucyoson_nm'}},
    cyo: {title: '連絡先住所：町', relation: {name: 'cyoRelation', column: 'cyo_nm'}},
    cyomoku: {title: '連絡先住所：町目', relation: {name: 'cyomokuRelation', column: 'cyomoku_nm'}},
    syosai_jusyo: {title: '連絡先住所：詳細住所'},
    tatemono: {title: '連絡先住所：建物名'},
    office_zip: {title: '勤務先住所：郵便番号', isParseString: true},
    office_todohuken: {title: '勤務先住所：都道府県', relation: {name: 'officeTodohukenRelation', column: 'todohuken_nm'}},
    office_sikucyoson: {title: '勤務先住所：市区町村', relation: {name: 'officeSikucyosonRelation', column: 'sikucyoson_nm'}},
    office_cyo: {title: '勤務先住所：町', relation: {name: 'officeCyoRelation', column: 'cyo_nm'}},
    office_cyomoku: {title: '勤務先住所：町目', relation: {name: 'officeCyomokuRelation', column: 'cyomoku_nm'}},
    office_banchi_ikou: {title: '勤務先住所：詳細住所'},
    office_tatemono: {title: '勤務先住所：建物名'},
    person_job: {title: '職業：本人'},
    person_kinmusaki: {title: '勤務先：本人'},
    person_kinzokukunensu: {title: '勤続年数：本人'},
    person_nesyu: {title: '年収：本人'},
    summer1_zokugara: {title: '続柄：合算人１', enum: {type: 'customer', path: 'reg_customer.tsudukigara2'}},
    summer1_zokugara_sonota: {title: '続柄その他：合算人１'},
    summer1_job: {title: '職業：合算人１'},
    summer1_kinmusaki: {title: '勤務先：合算人１'},
    summer1_kinzokukunensu: {title: '勤続年数：合算人１'},
    summer1_nesyu: {title: '年収：合算人１'},
    summer1_gassan_hiritsu: {title: '合算比率：合算人１'},
    summer2_zokugara: {title: '続柄：合算人２', enum: {type: 'customer', path: 'reg_customer.tsudukigara2'}},
    summer2_zokugara_sonota: {title: '続柄その他：合算人２'},
    summer2_job: {title: '職業：合算人２'},
    summer2_kinmusaki: {title: '勤務先：合算人２'},
    summer2_kinzokukunensu: {title: '勤続年数：合算人２'},
    summer2_nesyu: {title: '年収：合算人２'},
    summer3_gassan_hiritsu: {title: '合算比率：合算人２'},
    remark: {title: '備考　顧客情報'},
    cooperate_flg: {title: '連携フラグ', enum: {type: 'customer', path: 'reg_customer.kariire'}},
    genzai_kariire: {title: '現在の借入', enum: {type: 'customer', path: 'reg_customer.kariire'}},
    genzai_kariire_kingaku: {title: '現在の借入：総額'},
    genzai_kariire_kinyukikan: {title: '現在の借入：金融機関'},
    genzai_kariire_shiharai: {title: '現在の借入：月々返済総額'},
    genzai_kariire_biko: {title: '現在の借入：備考'},
    genkyo_yachin: {title: '現在の家賃'},
    genkyo_madori: {title: '現在の間取り', enum: {type: 'customer', path: 'reg_customer.layout'}},
    genkyo_menseki: {title: '現在の住居面積'},
    genkyo_menseki_tani: {title: '現在の住居面積　単位', enum: {type: 'customer', path: 'reg_customer.unit'}},
    teate_umu: {title: '住宅手当', enum: {type: 'customer', path: 'reg_customer.teate'}},
    teate_kingaku: {title: '住宅手当　金額'},
    tsukin_method: {title: '通勤方法', enum: {type: 'customer', path: 'reg_customer.commute'}},
    tsukin_method_sonota: {title: '通勤方法　その他'},
    tsukin_time_m: {title: '通勤時間　分'},
    genkyo_riyu: {title: '現居を借りた理由'},
    genkyo_human: {title: '現居に対する不満'},
    genkyo_kyojyu_start_ym: {title: '現居の居住開始時期'},
    Interest_loan: {title: '住宅ローン興味', enum: {type: 'customer', path: 'reg_customer.interest_loan'}},
    Interest_seminar: {title: 'セミナー参加興味', enum: {type: 'customer', path: 'reg_customer.interest_seminar'}},
    Interest_event: {title: 'イベント参加興味', enum: {type: 'customer', path: 'reg_customer.interest_event'}},
    syokai_information: {title: '紹介者情報'},
    syokai_client: {title: '紹介者　顧客番号'},
    line_user_id: {title: 'LINE　ユーザーID'},
    portal_member: {title: 'SUMITAS全国版サイト会員登録', enum: {type: 'project', path: 'existence'}},
    shop_member: {title: 'SUMITAS加盟店サイト会員登録', enum: {type: 'project', path: 'existence'}},
    //Relations
    tags: {title: 'タグ情報'},
    desiredConditions: {title: '希望条件'},
    clientHousemates: {title: '同居人'},
  },
  skipKey: [
    'created_at',
    'updated_at',
    'createdAt',
    'updatedAt',
    'deleted',
    'updated_user',
    'created_user',
    'client',
  ],
  relations: {
    tags: ['tag_id', 'tag1', 'tag2', 'tag3', 'tag4', 'tag5'],
    clientHistories: ['client','nitiji'],
    clientHousemates: [
      'child', 'name_last', 'name_last', 'kana_last', 'kana_first', 'birthday', 'sex', 'zokugara_sonota',
      'mail', 'tel', 'doitu', 'zip', 'todohuken', 'sikucyoson', 'cyo', 'cyomoku', 'syosai_jusyo', 'tatemono',
    ],
    desiredConditions: ['kibou'],
  },
  existence: [
    {text: 'あり', value: '1'},
    {text: 'なし', value: '0'}
  ],
  defaultRankTsuikyaku: '未設定',
  defaultStaff: 0,
  defaultStaffOption: {key: null, value: '未設定'},
  noDataText: '未設定',

  dmExportLabel: 'チェックした顧客のDMラベルを出入しますか？\n※郵便番号・住所が登録されていない場合は出力されません。',

  //ソート
  defaultSortTarget: 'client',
  sortTarget: [
    {
      key: 'client',
      value: '顧客番号'
    },
    {
      key: 'created_at',
      value: '顧客登録日'
    },
    {
      key: 'main_tantosha_ninmei_date',
      value: '反響日'
    },
    {
      key: 'rank_tsuikyaku',
      value: 'ランク'
    },
  ],
}
