module.exports = {
  deleteKeyList: [
    '__typename',
    'contact_method',
    'contact_request_review',
    'homonkibo1_date',
    'homonkibo1_time',
    'homonkibo2_date',
    'homonkibo2_time',
    'homonkibo3_date',
    'homonkibo3_time',
    'message',
    'todohukenRelation',
    'sikucyosonRelation',
    'cyoRelation',
    'cyomokuRelation',
  ],
  pagination: {
    initPage: 1,
    initPageSize: 50,
  },
  azukariShubetsu: [
    {text: '自社', value: '1'},
    {text: '他社', value: '0'},
  ],
  statusProperty: [
    {text: '作成中', value: '4'}, {text: '収集後未対応', value: '3'}, {text: '非元付（先物）', value: '18'},
    {text: '新規物確中', value: '2'}, {text: '参考情報（紹介不可）', value: '5'},
    {text: '取扱可（商談なし）', value: '10'}, {text: '取扱可（他社商談）', value: '11'}, {text: '取扱可（自社商談）', value: '12'},
    {text: '成約（他社済み）', value: '14'}, {text: '成約（自社済み）', value: '15'},
    {text: '売止', value: '16'}, {text: '査定中', value: '17'},
  ],
  //物件検索の物件ステータス初期値　取扱可（商談なし）
  defaultStatusProperty: '10',
  //物件区分
  kubun: {
    tochi: '10',//土地物件
    tochiKukakusumi: '11',//区画割済土地
    tochiBunjyou: '12',//分譲地
    apartment: '20',//マンション物件
    apartmentRoom: '21',//マンション部屋
    kodate: '30',//戸建物件
    kodateBunjyou: '35',//分譲戸建
    jigyou: '40',//事業用物件
  },
  isNew: {
    old: '0',
    new: '1'
  },
  //物件設備
  facilities: [
    {text: 'オール電化', value: 'facility_010_011'}, {text: 'プロパンガス（個別）', value: 'facility_010_003'},
    {text: 'プロパンガス（集中）', value: 'facility_010_002'}, {text: '都市ガス', value: 'facility_010_001'},
    {text: 'ガス（その他）', value: 'facility_010_013'}, {text: '井戸', value: 'facility_010_006'},
    {text: '上水道', value: 'facility_010_004'}, {text: '水道（その他）', value: 'facility_010_014'},
    {text: '下水道', value: 'facility_010_005'}, {text: '汲取', value: 'facility_010_009'},
    {text: '浄化槽', value: 'facility_010_007'}, {text: '排水（その他）', value: 'facility_010_015'},
    {text: '側溝', value: 'facility_010_008'}, {text: '太陽光発電システム', value: 'facility_010_012'},
    {text: '電気', value: 'facility_010_010'}, {text: 'IHクッキングヒーター', value: 'facility_020_004'},
    {text: 'ガスコンロ', value: 'facility_020_008'}, {text: '電気コンロ', value: 'facility_020_009'},
    {text: 'アイランドキッチン', value: 'facility_020_003'}, {text: 'カウンターキッチン', value: 'facility_020_002'},
    {text: 'システムキッチン', value: 'facility_020_001'}, {text: 'ディスポーザー', value: 'facility_020_007'},
    {text: '浄水器', value: 'facility_020_006'}, {text: '食器洗浄乾燥機', value: 'facility_020_005'},
    {text: 'TV付浴室', value: 'facility_030_006'}, {text: 'オートバス', value: 'facility_030_004'},
    {text: 'シャンプードレッサ', value: 'facility_030_010'}, {text: 'トイレ2カ所', value: 'facility_030_009'},
    {text: 'バス1坪以上', value: 'facility_030_007'}, {text: 'ミストサウナ', value: 'facility_030_005'},
    {text: '給湯器', value: 'facility_030_012'}, {text: '室内洗濯機設置可', value: 'facility_030_011'},
    {text: '洗濯機置場', value: 'facility_030_014'}, {text: '省エネ給湯器', value: 'facility_030_013'},
    {text: '洗浄便座', value: 'facility_030_008'}, {text: '追炊機能', value: 'facility_030_002'},
    {text: '浴室に窓', value: 'facility_030_003'}, {text: '浴室乾燥機', value: 'facility_030_001'},
    {text: 'ジェットバス', value: 'facility_030_015'}, {text: 'オーディオバス', value: 'facility_030_016'},
    {text: '露天風呂', value: 'facility_030_017'}, {text: '24時間換気システム', value: 'facility_040_006'},
    {text: 'エアコン', value: 'facility_040_007'}, {text: '冷房', value: 'facility_040_008'},
    {text: '暖房', value: 'facility_040_009'}, {text: '床暖房', value: 'facility_040_001'},
    {text: 'ガス暖房可', value: 'facility_040_002'}, {text: 'ガス暖房', value: 'facility_040_010'},
    {text: '石油暖房', value: 'facility_040_011'}, {text: '高気密断熱住宅', value: 'facility_040_005'},
    {text: '複層ガラス', value: 'facility_040_004'}, {text: '掘ごたつ', value: 'facility_040_003'},
    {text: 'ウォークインクローゼット', value: 'facility_050_003'}, {text: 'グルニエ（屋根裏部屋）', value: 'facility_050_005'},
    {text: 'シューズインクローゼット', value: 'facility_050_007'}, {text: '収納スペース', value: 'facility_050_008'},
    {text: '床下収納', value: 'facility_050_001'}, {text: '全居室収納有り', value: 'facility_050_006'},
    {text: '天井収納', value: 'facility_050_002'}, {text: '納戸有り', value: 'facility_050_004'},
    {text: '2面バルコニー', value: 'facility_060_003'}, {text: '3面バルコニー', value: 'facility_060_004'},
    {text: 'ウッドデッキ', value: 'facility_060_007'}, {text: 'ルーフバルコニー', value: 'facility_060_001'},
    {text: 'ワイドバルコニー', value: 'facility_060_002'}, {text: '庭10坪以上', value: 'facility_060_005'},
    {text: '南庭', value: 'facility_060_006'}, {text: '専用庭', value: 'facility_060_008'},
    {text: '庭', value: 'facility_060_009'}, {text: '24時間セキュリティ', value: 'facility_070_008'},
    {text: 'オートロック', value: 'facility_070_001'}, {text: 'カードキー', value: 'facility_070_005'},
    {text: 'シャッター雨戸', value: 'facility_070_006'}, {text: 'ディンプルキー', value: 'facility_070_004'},
    {text: 'モニター付きインターホン', value: 'facility_070_002'}, {text: '火災警報器', value: 'facility_070_009'},
    {text: '防犯カメラ', value: 'facility_070_003'}, {text: '防犯ガラス', value: 'facility_070_007'},
    {text: '光ファイバー', value: 'facility_080_009'}, {text: 'CATV', value: 'facility_080_010'},
    {text: '有線放送', value: 'facility_080_011'}, {text: 'BS端子', value: 'facility_080_012'},
    {text: 'CS', value: 'facility_080_013'}, {text: 'CATVインターネット', value: 'facility_080_014'},
    {text: 'インターネット対応', value: 'facility_080_015'}, {text: 'バリアフリー', value: 'facility_090_013'},
    {text: 'ベッド', value: 'facility_090_009'}, {text: 'ロフト', value: 'facility_090_012'},
    {text: '角部屋', value: 'facility_090_019'}, {text: '最上階', value: 'facility_090_020'},
    {text: '高層階', value: 'facility_090_021'}, {text: '出窓', value: 'facility_090_011'},
    {text: '振分', value: 'facility_090_014'}, {text: '吹き抜け', value: 'facility_090_017'},
    {text: '全居室フローリング', value: 'facility_090_015'}, {text: '全室2面採光', value: 'facility_090_016'},
    {text: '天井高2.5m以上', value: 'facility_090_018'}, {text: '冷蔵庫', value: 'facility_090_010'},
    {text: 'エレベーター', value: 'facility_100_014'}, {text: 'キッズルーム', value: 'facility_100_018'},
    {text: '託児所', value: 'facility_100_019'}, {text: 'トランクルーム', value: 'facility_100_016'},
    {text: 'バリアフリー', value: 'facility_100_003'}, {text: 'ベッド', value: 'facility_100_007'},
    {text: 'ペット用施設', value: 'facility_100_017'}, {text: 'ロフト', value: 'facility_100_002'},
    {text: '角部屋', value: 'facility_100_012'}, {text: '出窓', value: 'facility_100_001'},
    {text: '振分', value: 'facility_100_010'}, {text: '吹き抜け', value: 'facility_100_005'},
    {text: '全居室フローリング', value: 'facility_100_004'}, {text: '全面2面採光', value: 'facility_100_009'},
    {text: '宅配BOX', value: 'facility_100_015'}, {text: '地下室', value: 'facility_100_011'},
    {text: '最上階', value: 'facility_100_013'}, {text: '天井高2.5m以上', value: 'facility_100_006'},
    {text: '2階建', value: 'facility_100_020'}, {text: '3階建以上', value: 'facility_100_021'},
    {text: '冷蔵庫', value: 'facility_100_008'}, {text: '制震', value: 'facility_110_001'},
    {text: '耐震', value: 'facility_110_003'}, {text: '免震', value: 'facility_110_002'},
    {text: 'シャッター車庫', value: 'facility_120_006'}, {text: '軽駐車可能', value: 'facility_120_004'},
    {text: '1BOX駐車可', value: 'facility_120_005'}, {text: '普通車駐車可能', value: 'facility_120_003'},
    {text: '駐車場2台分', value: 'facility_120_007'}, {text: '駐車場3台分', value: 'facility_120_008'},
    {text: '自走式駐車場', value: 'facility_120_009'}, {text: '平面駐車場', value: 'facility_120_010'},
    {text: 'ビルトインガレージ', value: 'facility_120_011'}, {text: 'ハイルーフ駐車場', value: 'facility_120_013'},
    {text: 'ハイルーフ駐車場空きあり', value: 'facility_120_012'}, {text: 'EV車充電設備', value: 'facility_120_014'},
    {text: '来客用駐車場', value: 'facility_120_015'}, {text: 'バイク置場', value: 'facility_130_001'},
    {text: '駐輪場', value: 'facility_130_002'}, {text: 'プール', value: 'facility_140_002'},
    {text: '温泉付', value: 'facility_140_001'}, {text: 'BELS/省エネ基準適合認定書', value: 'facility_500_009'},
    {text: '建設住宅性能評価書', value: 'facility_500_002'}, {text: '建築確認完了検査済証', value: 'facility_500_005'},
    {text: '住宅性能保証付', value: 'facility_500_007'}, {text: '設計住宅性能評価書', value: 'facility_500_001'},
    {text: '耐震基準適合証明書', value: 'facility_500_004'}, {text: '長期優良住宅認定通知書', value: 'facility_500_003'},
    {text: '低炭素住宅', value: 'facility_500_008'}, {text: '法適合状況調査報告書', value: 'facility_500_006'},
    {text: 'フラット35・S適合証明書', value: 'facility_510_004'}, {text: '建築士等の建物検査報告書', value: 'facility_510_005'},
    {text: '住宅診断済み', value: 'facility_510_001'}, {text: '瑕疵保険（国交省指定）保証', value: 'facility_510_002'},
    {text: '瑕疵保証付（不動産会社独自）', value: 'facility_510_003'}, {text: '修繕・点検の記録', value: 'facility_520_002'},
    {text: '新築時・増改築時の設計図', value: 'facility_520_001'}, {text: 'オーシャンビュー', value: 'facility_530_002'},
    {text: 'ゴルフ場が近い', value: 'facility_530_009'}, {text: 'スキー場が近い', value: 'facility_530_010'},
    {text: 'テニスコートが近い', value: 'facility_530_011'}, {text: 'リゾート向き', value: 'facility_530_001'},
    {text: 'リバーサイド', value: 'facility_530_004'}, {text: '花火大会鑑賞', value: 'facility_530_006'},
    {text: '海に近い', value: 'facility_530_003'}, {text: '湖・池が見える', value: 'facility_530_007'},
    {text: '山が見える', value: 'facility_530_008'}, {text: '田園風景', value: 'facility_530_005'},
    {text: '24時間ゴミ出し可', value: 'facility_540_006'}, {text: 'タワーマンション', value: 'facility_540_002'},
    {text: 'デザイナーズ', value: 'facility_540_001'}, {text: 'ペット相談', value: 'facility_540_011'},
    {text: 'ログハウス', value: 'facility_540_013'}, {text: '外観タイル張り', value: 'facility_540_003'},
    {text: '角地', value: 'facility_540_015'}, {text: '楽器相談', value: 'facility_540_012'},
    {text: '閑静な住宅街', value: 'facility_540_008'}, {text: '区画整理地内', value: 'facility_540_017'},
    {text: '古民家風', value: 'facility_540_014'}, {text: '高台に立地', value: 'facility_540_004'},
    {text: '始発駅', value: 'facility_540_005'}, {text: '事務所使用可', value: 'facility_540_010'},
    {text: '整形地', value: 'facility_540_016'}, {text: '二世帯向き', value: 'facility_540_018'},
    {text: '日当たり良好', value: 'facility_540_007'}, {text: '避暑地', value: 'facility_540_009'},
  ],

  //間取り
  layoutType: [
    {text: 'R', value: '10'}, {text: 'K', value: '20'}, {text: 'SK', value: '25'},
    {text: 'DK', value: '30'}, {text: 'SDK', value: '35'}, {text: 'LK', value: '40'},
    {text: 'SLK', value: '45'}, {text: 'LDK', value: '50'}, {text: 'SLDK', value: '55'},
  ],
  //地目
  chimoku: [
    {text: '宅地', value: '01'}, {text: '田', value: '02'}, {text: '畑', value: '03'},
    {text: '山林', value: '04'}, {text: '雑種地', value: '05'}, {text: 'その他', value: '09'},
    {text: '原野', value: '10'}, {text: '運河用地', value: '11'}, {text: '塩田', value: '12'},
    {text: '境内地', value: '13'}, {text: '公園', value: '14'}, {text: '公衆用道路', value: '15'},
    {text: '鉱泉地', value: '16'}, {text: '水道用地', value: '17'}, {text: '井溝', value: '18'},
    {text: 'ため池', value: '19'}, {text: '池沼', value: '20'}, {text: '堤', value: '21'},
    {text: '保安林', value: '22'}, {text: '牧場', value: '23'}, {text: '墓地', value: '24'},
    {text: '用悪水路', value: '25'}, {text: '旧既存宅地', value: '26'},
  ],
  //接道状況
  setsudouState: [
    {text: '一方', value: '1'}, {text: '角地', value: '2'}, {text: '三方', value: '3'},
    {text: '四方', value: '4'}, {text: '二方(徐角地)', value: '5'}, {text: '接道なし', value: '10'},
  ],
  //向き
  direction: [
    {text: '北', value: '1'}, {text: '北東', value: '2'}, {text: '東', value: '3'}, {text: '南東', value: '4'},
    {text: '南', value: '5'}, {text: '南西', value: '6'}, {text: '西', value: '7'}, {text: '北西', value: '8'},
  ],
  //構造
  kouzo: [
    {text: '木造', value: '01'}, {text: 'ブロック', value: '02'}, {text: '鉄骨造', value: '03'},
    {text: 'RC', value: '04'}, {text: 'SRC', value: '05'}, {text: 'PC', value: '06'},
    {text: 'HPC', value: '07'}, {text: '軽量鉄骨', value: '09'}, {text: 'ALC', value: '10'},
    {text: 'RCB', value: '11'}, {text: 'WRC', value: '12'}, {text: 'その他', value: '30'},
  ],
  //都市計画
  cityplan: [
    {text: '市街化区域', value: '1'}, {text: '調整区域', value: '2'}, {text: '非線引区域', value: '3'},
    {text: '区域外', value: '4'}, {text: '準都市区域', value: '5'},
  ],
  //用途地域
  yotochiiki: [
    {text: '第一種低層住居専用地域', value: '01'}, {text: '第二種中高層住居専用地域', value: '02'},
    {text: '第二種住居地域', value: '03'}, {text: '近隣商業地域', value: '04'},
    {text: '商業地域', value: '05'}, {text: '準工業地域', value: '06'},
    {text: '工業地域', value: '07'}, {text: '工業専用地域', value: '08'},
    {text: '第二種低層住居専用地域', value: '10'}, {text: '第一種中高層住居専用地域', value: '11'},
    {text: '第一種住居地域', value: '12'}, {text: '準住居地域', value: '13'},
    {text: '田園住居地域', value: '14'}, {text: '指定無', value: '99'},
  ],
  //土地権利
  tochikenri: [
    {text: '所有権', value: '01'}, {text: '普通地上権', value: '04'},
    {text: '定期地上権', value: '05'}, {text: '普通賃借権', value: '06'},
    {text: '定期賃借権', value: '07'}, {text: '旧法地上権', value: '21'},
    {text: '旧法賃借権', value: '23'},
  ],
  //現況（土地）
  genkyoTochi: [
    {text: '更地', value: '1'}, {text: '古家あり', value: '2'},
    {text: '未造成', value: '3'}, {text: '古家あり(更地渡し)', value: '4'},
    {text: 'その他', value: '9'},
  ],
  //現況（建物）
  genkyoTatemono: [
    {text: '居住中（居住用物件）', value: '1'}, {text: '空', value: '2'},
    {text: '賃貸中（投資用物件）', value: '3'}, {text: '未完成', value: '4'},
    {text: '完成済', value: '9'},
  ],
  //土地計測の種類
  tochiMensekiHoushiki: [
    {text: '公簿', value: '1'}, {text: '実測', value: '2'},
  ],

  tochiMenseki: {
    public: '1',
    actual: '2',
  },

  //専有面積の種類
  senyuuMensekiHoushiki: [
    {text: '壁芯', value: '1'}, {text: '内法', value: '2'},
  ],
  //建築条件
  kenchikujoken: [
    {text: '建築条件付', value: '1'}, {text: '更地渡し(建築条件なし)', value: '2'},
    {text: '現況渡し(建築条件なし)', value: '3'}, {text: '相談', value: '4'},
    {text: 'オーナーチェンジ', value: '5'}, {text: '建築条件なし', value: '6'},
  ],

  kenchikujokenCondition: [
    {text: '建築条件：有り', value: '1'},
    {text: '建築条件：相談', value: '4'},
    {text: '建築条件：無し', value: '6'},
    {text: '建築条件：未設定'},
  ],

  //最適用途
  saitekiyouto: [
    {text: '住宅用地', value: '01'}, {text: 'マンション用地', value: '02'},
    {text: 'ビル用地', value: '03'}, {text: '店舗用地', value: '04'},
    {text: '工業用地', value: '05'}, {text: '配送センター用地', value: '06'},
    {text: '営業所用地', value: '07'}, {text: '保養所用地', value: '08'},
    {text: '事務所用地', value: '09'}, {text: '別荘用地', value: '10'},
    {text: '倉庫用地', value: '11'}, {text: '資材置場用地', value: '12'},
    {text: '家庭菜園用地', value: '13'}, {text: 'アパート用地', value: '14'},
    {text: '社宅社員寮', value: '15'}, {text: '病院診療所', value: '16'},
    {text: '畑・農地', value: '17'}, {text: '事業用地', value: '18'},
    {text: '駐車場用地', value: '20'}, {text: 'リゾート向き', value: '21'},
    {text: 'その他用地', value: '99'},
  ],
  //国土法届出
  kokudohoutodokede: [
    {text: '要', value: '1'}, {text: '届出中', value: '2'}, {text: '不要', value: '3'},
  ],
  //明け渡し
  akewatashi: {
    soku: '1',
    soudan: '2',
    kijitu: '3',
    sonota: '9'
  },
  //明け渡し区分
  akewatashiKubun: [
    {text: '即', value: '1'}, {text: '相談', value: '2'},
    {text: '期日指定', value: '3'}, {text: '未記入', value: '9'},
  ],
  //取引態様
  torihikitaiyo: [
    {text: '売主', value: '1'}, {text: '代理', value: '2'},
    {text: '専属専任', value: '3'}, {text: '専任', value: '4'},
    {text: '一般', value: '5'}, {text: '媒介', value: '6'},
  ],
  //管理形態
  kanriKeitai: [
    {text: '自主管理', value: '1'}, {text: '一部委託', value: '2'}, {text: '全部委託', value: '3'},
  ],
  lableName: [
    '物件番号',
    '種別',
    '物件ステータス',
    '物件登録日',
    '物件名',
    '所在地',
    '交通',
    '価格',
    '自他',
  ],
  land: {
    tochi_keisoku_houshiki: {
      kobo: '1',
      genkyo: '2',
    }
  },
  noDataText: '未設定',
  // ローンの金利、支払い回数
  loanStatus: {
    rates: 1.2,//金利
    term: 420,//支払い回数
  },
  contactMethod: [
    {text: 'メール', value: '0'},
    {text: '電話', value: '1'},
  ],
  contactRequestReview: [
    {text: '物件の詳細をもっと知りたい', value: '17'},
    {text: '内見（見学）希望', value: '01'},
    {text: 'オンライン接客希望', value: '12'},
    {text: '来店希望', value: '04'},
    {text: '条件に合うほかの物件も紹介してほしい', value: '17'},
    {text: 'その他', value: '17'},
  ],
  contactRequestHidden: ['物件の詳細をもっと知りたい', '条件に合うほかの物件も紹介してほしい', 'その他'],
  contactRequestShow: {
    preview: '内見（見学）希望',
    onlineCustomerService: 'オンライン接客希望',
    toVisit: '来店希望',
  },
  homonkiboTime: [
    {text: '9時頃', key: '9'},
    {text: '10時頃', key: '10'},
    {text: '11時頃', key: '11'},
    {text: '12時頃', key: '12'},
    {text: '13時頃', key: '13'},
    {text: '14時頃', key: '14'},
    {text: '15時頃', key: '15'},
    {text: '16時頃', key: '16'},
    {text: '17時頃', key: '17'},
  ],
  path: {
    basicInformation: [
      'name_last',
      'name_first',
      'kana_last',
      'kana_first',
      'zip',
      'banchi_ikou',
      'tel',
      'mail',
      'inquiry_content',
      'contact_method',
      'contact_request_review',
      'message',
      'homonkibo1_date',
      'homonkibo1_time',
      'homonkibo2_date',
      'homonkibo2_time',
      'homonkibo3_date',
      'homonkibo3_time',
    ],
  },
  priceType: {
    original: 'kakaku',
    assumedYield: 'sotei_rimawari',
    assumedIncome: 'sotei_shunyu',
  },
  btnName: {
    selected: '選択済み',
    select: '選択',
  },

  //物件種別
  bukkenShubetsu: [
    {text: '売地', value: '1101'}, {text: '借地権譲渡', value: '1102'}, {text: '底地権譲渡', value: '1103'},
    {text: '新築戸建', value: '1201'}, {text: '中古戸建', value: '1202'}, {text: '新築テラスハウス', value: '1203'},
    {text: '中古テラスハウス', value: '1204'}, {text: '新築マンション', value: '1301'}, {text: '中古マンション', value: '1302'},
    {text: '新築公団', value: '1303'}, {text: '中古公団', value: '1304'}, {text: '新築公社', value: '1305'},
    {text: '中古公社', value: '1306'}, {text: '新築タウンハウス', value: '1307'}, {text: '中古タウンハウス', value: '1308'},
    {text: 'リゾートマンション', value: '1309'}, {text: '店舗', value: '1401'}, {text: '店舗付住宅', value: '1403'},
    {text: '住宅付店舗', value: '1404'}, {text: '事務所', value: '1405'}, {text: '店舗・事務所', value: '1406'},
    {text: 'ビル', value: '1407'}, {text: '工場', value: '1408'}, {text: 'マンション', value: '1409'},
    {text: '倉庫', value: '1410'}, {text: 'アパート', value: '1411'}, {text: '寮', value: '1412'},
    {text: '旅館', value: '1413'}, {text: 'ホテル', value: '1414'}, {text: '別荘', value: '1415'},
    {text: 'リゾートマンション', value: '1416'}, {text: 'モーテル', value: '1420'}, {text: '医院', value: '1499'},
    {text: 'ガソリンスタンド', value: '1502'}, {text: '特殊浴場', value: '1505'}, {text: 'サウナ', value: '1506'},
    {text: '保養所', value: '1507'}, {text: '作業所', value: '1509'}, {text: '駐車場', value: '1510'},
    {text: 'その他', value: '1599'},
  ],

  propertyAdManagementTypes : {
    '081' : 'ad_mgmt_081',
    '060' : 'ad_mgmt_060',
  },

  propertyAdManagementPublishable : {
     private : '0',
     public : '1',
  }
}
