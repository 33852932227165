module.exports = {
  lableName: [
    '物件番号',
    '種別',
    '物件名',
    '所在地',
    '交通',
    '価格',
    '自他',
    '　',
  ],

  key: [
    '物件種別',
    '取込媒体',
    '物件ステータス',
    '物件名',
    '物件番号',
    '物件価格',
    'エリア',
    '自社・他社',
    '取引様態',
    '業者取引様態',
    '元付業者',
    '担当者',
    '築年数',
    '間取り',
    '土地面積',
    '建物面積',
    '専有面積',
    '物件登録日',
    '価格変更日',
    '物件確認日',
    'タグ'
  ],

  //種別
  propertyShubetsu: [
    {text: '土地', value: '10'},
    {text: 'マンション', value: '20'},
    {text: '戸建て', value: '30'},
    {text: '事業所', value: '40'},
  ],

  //土地種別
  tochiShubetsu: [
    {text: '売地', value: '1101'}, {text: '借地権譲渡', value: '1102'}, {text: '底地権譲渡', value: '1103'}
  ],

  //戸建て種別
  kodateShubetsu: [
    {text: '新築戸建', value: '1201'}, {text: '中古戸建', value: '1202'}, {text: '新築テラスハウス', value: '1203'}, {text: '中古テラスハウス', value: '1204'}
  ],

  //マンション種別
  mansionShubetsu: [
    {text: '新築マンション', value: '1301'}, {text: '中古マンション', value: '1302'},
    {text: '新築公団', value: '1303'}, {text: '中古公団', value: '1304'}, {text: '新築公社', value: '1305'},
    {text: '中古公社', value: '1306'}, {text: '新築タウンハウス', value: '1307'}, {text: '中古タウンハウス', value: '1308'},
    {text: 'リゾートマンション', value: '1309'}
  ],

  //事業用種別
  businessShubetsu: [
    {text: '店舗', value: '1401'}, {text: '店舗付住宅', value: '1403'},
    {text: '住宅付店舗', value: '1404'}, {text: '事務所', value: '1405'}, {text: '店舗・事務所', value: '1406'},
    {text: 'ビル', value: '1407'}, {text: '工場', value: '1408'}, {text: 'マンション', value: '1409'},
    {text: '倉庫', value: '1410'}, {text: 'アパート', value: '1411'}, {text: '寮', value: '1412'},
    {text: '旅館', value: '1413'}, {text: 'ホテル', value: '1414'}, {text: '別荘', value: '1415'},
    {text: 'リゾートマンション', value: '1416'}, {text: 'モーテル', value: '1420'}, {text: '医院', value: '1499'},
    {text: 'ガソリンスタンド', value: '1502'}, {text: '特殊浴場', value: '1505'}, {text: 'サウナ', value: '1506'},
    {text: '保養所', value: '1507'}, {text: '作業所', value: '1509'}, {text: '駐車場', value: '1510'},
    {text: 'その他', value: '1599'}
  ],

  //建築条件
  kenchikujoken: [
    {text: '建築条件付', value: '1'}, {text: '更地渡し(建築条件なし)', value: '2'},
    {text: '現況渡し(建築条件なし)', value: '3'}, {text: '相談', value: '4'},
    {text: 'オーナーチェンジ', value: '5'}, {text: '建築条件なし', value: '6'},
  ],

  //取込媒体
  transactionType: [
    {text: '媒介', value: '1'},
    {text: '一般・専任', value: '2'},
    {text: '専属専任', value: '3'},
    {text: '売主', value: '4'},
  ],

  //物件ステータス
  statusProperty: [
    {text: '新規物確中', value: '2'}, {text: '収集後未対応', value: '3'}, {text: '作成中', value: '4'},
    {text: '参考情報（紹介不可）', value: '5'}, {text: '取扱可（商談なし）', value: '10'}, {text: '取扱可（他社商談）', value: '11'},
    {text: '取扱可（自社商談）', value: '12'}, {text: '成約（他社済み）', value: '14'}, {text: '成約（自社済み）', value: '15'},
    {text: '売止', value: '16'}, {text: '査定中', value: '17'}, {text: '非元付（先物）', value: '18'}
  ],

  //現況（土地）
  genkyoTochies: [
    {text: '更地', value: '1'}, {text: '古家あり', value: '2'},
    {text: '未造成', value: '3'}, {text: '古家あり(更地渡し)', value: '4'},
    {text: 'その他', value: '9'},
  ],
  //現況（建物）
  genkyoTatemonos: [
    {text: '居住中（居住用物件）', value: '1'}, {text: '空', value: '2'},
    {text: '賃貸中（投資用物件）', value: '3'}, {text: '未完成', value: '4'},
    {text: '完成済', value: '9'},
  ],

  //自社/他社
  azukariShubetsu: [
    {text: '自社', value: '1'},
    {text: '他社', value: '0'},
  ],

  //自社/他社
  transactionsTorihikiTaiyoCompany: [
    {text: '売主', value: '1'},
    {text: '代理', value: '2'},
    {text: '専属専任', value: '3'},
    {text: '専任', value: '4'},
    {text: '一般', value: '5'},
    {text: '媒介', value: '6'},
  ],
  transactionsTorihikiTaiyoOtherCompany: [
    {text: '媒介', value: '6'},
  ],

  // 取込媒体
  importMedias: [
    {text: '媒体なし', value: '01'},
    {text: 'SUUMO', value: '02'},
    {text: 'HOMES', value: '03'},
    {text: '不動産ジャパン', value: '04'},
    {text: 'カチタス', value: '05'},
    {text: 'リプライス', value: '06'},
    {text: '楽待', value: '07'},
    {text: 'ATBB', value: '08'},
  ],
  kenchikuJokens: [
    {text: '有り', value: '1'},
    {text: '無し', value: '6'},
    {text: '相談', value: '4'},
  ],

  typeTochi: '10',
  typeBunjyouchi: '11',
  typeMansion: '21',
  typeKodate: '30',
  typeBusiness: '40',
  typeOther: ['21', '30', '40'],
  companyValue: '1',
  companyOtherValue: '0',

  //取引態様
  torihikitaiyo: [
    {text: '売主', value: '1'}, {text: '代理', value: '2'},
    {text: '専属専任', value: '3'}, {text: '専任', value: '4'},
    {text: '一般', value: '5'}, {text: '媒介', value: '6'},
  ],

  //業者取引態様
  gyoshaTorihikitaiyo: [
    {text: '売主（自社転売）', value: '1'},
    {text: '買主（自社買取）', value: '2'},
    {text: '交換', value: '3'},
    {text: '媒介（売）', value: '4'},
    {text: '媒介（買）', value: '5'},
    {text: '代理', value: '6'},
  ],

  //元付業者
  motoduke: [
    {text: '元付け', value: '1'},
    {text: '元付け', value: '2'},
    {text: '元付け', value: '3'},
    {text: '元付け', value: '4'},
    {text: '元付け', value: '5'},
    {text: '元付け', value: '6'},
  ],

  //築年数の選択肢
  buildingAge: [
    {key: 1, value: 1}, {key: 3, value: 3}, {key: 5, value: 5}, {key: 7, value: 7},
    {key: 10, value: 10}, {key: 15, value: 15}, {key: 20, value: 20}, {key: 25, value: 25},
    {key: 30, value: 30}
  ],

  //間取り
  layoutType: [
    {text: 'R', value: '10'}, {text: 'K', value: '20'}, {text: 'SK', value: '25'},
    {text: 'DK', value: '30'}, {text: 'SDK', value: '35'}, {text: 'LK', value: '40'},
    {text: 'SLK', value: '45'}, {text: 'LDK', value: '50'}, {text: 'SLDK', value: '75'},
  ],

  // 面積 単位
  mensekiUnit: [
    {text: "m²", value: "1"}, {text: " 坪 ", value: "2"},
  ],

  // 面積 坪
  mensekiTubo: [
    {key: 1, value: 1}, {key: 3, value: 3}, {key: 5, value: 5}, {key: 7, value: 7},
    {key: 10, value: 10}, {key: 15, value: 15}, {key: 20, value: 20}, {key: 25, value: 25},
    {key: 30, value: 30}, {key: 35, value: 35}, {key: 40, value: 40}, {key: 45, value: 45},
    {key: 50, value: 50}, {key: 55, value: 55}, {key: 60, value: 60}, {key: 65, value: 65},
    {key: 70, value: 70},
  ],

  // 面積 平方メートル
  mensekiMeter: [
    {key: 1, value: 1}, {key: 3, value: 3}, {key: 5, value: 5}, {key: 7, value: 7},
    {key: 10, value: 10}, {key: 15, value: 15}, {key: 20, value: 20}, {key: 25, value: 25},
    {key: 30, value: 30}, {key: 35, value: 35}, {key: 40, value: 40}, {key: 45, value: 45},
    {key: 50, value: 50}, {key: 55, value: 55}, {key: 60, value: 60}, {key: 65, value: 65},
    {key: 70, value: 70}, {key: 75, value: 75}, {key: 80, value: 80}, {key: 85, value: 85},
    {key: 90, value: 90}, {key: 95, value: 95}, {key: 100, value: 100}, {key: 105, value: 105},
  ],

  //タグ 条件式
  tagsRelation: [
    {value: 'all', text: '全てを含む'}, {value: 'any', text: 'いずれかを含む'}, {value: 'not_all', text: '全て含まない'}
  ],

  defaultTags :[
    {tag_id: null},
    {tag_id: null},
    {tag_id: null},
  ],
  pagination: {page: 1, pageSize: 50},

  //
  companyType: [
    {text: '媒介', value: '0'},
    {text: '売主', value: '1'},
  ],

  defaultStaff: 0,
  defaultStaffOption: {key: null, value: '未設定'},

  //unit
  unit: [ // 単位 - 希望面積 // m&#178; &#13217;
    {text: "m²", value: "1"}, {text: "坪", value: "2"},
  ],

  //面積のチェック
  kibouUnit: {
    squareMeter: '1',
    tsubo: '2'
  },
  propertyTypes: [
    {text: '土地', value: '10'},
    {text: '分譲地', value: '11'},
    {text: 'マンション', value: '21'},
    {text: '戸建て', value: '30'},
    {text: '事業用', value: '40'},
  ],
}
