module.exports = {
  mailType: {
    individual: '01',
    collective: '02',
    matching: '03',
    other: '99',
  },
  mailSendFlg: {
    drafts: '1',
    reservation: '2',
    delivered: '3',
  },
  mailBodyType: {
    text: '1',
    html: '2',
  },
  receiveOpenNotificationMailFlg: {
    unNotice: 0,
    notice: 1
  },
  textMailDraft: 'メール(下書き)',
  pathMail: {
    basicInformation: [
      'title',
      'body',
    ],
  },
  defaultImage: 'https://sis-customer-api-prd-file.s3.ap-northeast-1.amazonaws.com/noImage.png',
  inquiryContentTitle: {
    property_url: '物件詳細画面',
    contact_method: '希望連絡方法',
    message: 'お問合せ内容',
    contact_type: '査定種別',
    property_type: '物件種別',
    property_location: '物件所在地',
    building_area: '物件所在地',
    land_area: '建物(専有)面積',
    floor_plan: '間取り',
    year: '築年',
    situation: '現況',
    rent: '賃料',
    name: 'ご名義',
    reason: 'ご依頼理由',
    hope: '希望など',
    requests: 'ご要望など',
    type: '物件種別',
    address: '物件住所',
    apartment_name: 'マンション名',
    room_number: '部屋番号',
    building_name: '建物名',
    exclusive_area: '専有面積',
    total_floor_area: '延べ床面積',
    age: '築年数',
    status: '物件の状況',
    relationship: '物件との関係',
    property_image: '物件画像',
    acquisition_time: '取得時期',
    purchase_amount: '購入金額',
    structure: '構造',
    number_of_floors_of_the_building: '建物の階数',
    property_status: '物件の状態',
    remodeling_history: 'リフォーム歴',
    suggested_selling_price: '売却希望価格',
    borrowing_balance: '借入残債',
    relationship_with_the_owner_of_the_property: '物件の所有者との関係',
    consultation_with_other_companies: '他社への相談',
    desired_time_to_sell: '売却希望時期',
    sale_assessment_detail: '売却査定内容',
    rent_assessment_detail: '賃貸査定内容',
    other_matters: 'その他連絡事項',
    first_choice_date: '第一希望日',
    second_choice_date: '第二希望日',
    service: 'サービス',
    year_of_contruction: '築年',
    request_reason: 'ご依頼の理由',
    request: '査定会社への要望',
    desire_time_sell: '売却希望時期',
    number_of_sending_companies: '送信社数',
    homonkibo1_date: '第1希望日',
    homonkibo1_time: '第1希望時間',
    homonkibo2_date: '第2希望日',
    homonkibo2_time: '第2希望時間',
    homonkibo3_date: '第3希望日',
    homonkibo3_time: '第3希望時間',
    contact_request_review: 'お問い合わせ内容',
  },

  docType: `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">`,
  header: `
  <head>
    <meta name="viewport" content="target-densitydpi=device-dpi,width=device-width,maximum-scale=1.0,user-scalable=yes">
    <meta http-equiv="Content-Language" content="ja">
    <meta charset="shift_jis">
    <style type="text/css">
    .property-table-html {
      width: 100%;
    }
    .property-table-html p {
      color: #000;
    }
    .property-table-html tr {
      display: block;
      margin: 0 0 20px;
      border: 1px solid #d1d1d1;
    }
    .property-table-html th {
      display: block;
      padding: 10px 20px;
      background: #f0f0f0;
      color: #000;
      font-weight: normal;
      text-align: left;
    }
    .property-table-html td {
      display: block;
      width: 100%;
      padding: 20px;
      font-size: 13px;
      box-sizing: border-box;
    }
    .property-table-html td img {
      margin-right: 28px;
      vertical-align: top;
    }
    .property-table-html td div {
      display: inline-block;
    }
    .property-table-html td div p {
      margin: 0 0 0px;
    }
    .property-table-html td div span {
      color: #d30000;
      font-weight: bold;
    }
    .property-table-html td div span span {
      font-size: 18px;
    }
    .property-table-html td div a.property-url {
      display: inline-block;
      padding: 13px 47px;
      margin: 10px 0 0;
      background: #454545;
      border-radius: 5px;
      color: #fff!important;
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      text-decoration: none;
    }
    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin:0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    </style>
  </head>`,
  matchingMail: {
    NONE: 0,
    INCLUDE: 1,
    EXCLUDE: 2,
    LINK_TRACKING: 3,
  },
  matchingMailMap: [
    {text: 'NO', value: 0},
    {text: 'マッチングメール送信を除く', value: 2},
    {text: 'マッチングメール送信のみ表示', value: 1},
    {text: 'リンクトラッキングのみ表示', value: 3},
  ],

  matchingMailList: [
    {text: 'マッチングメール送信を除く', value: 2},
    {text: 'マッチングメール送信のみ表示', value: 1},
    {text: 'リンクトラッキングのみ表示', value: 3},
  ],
  trackingFlg:{
    FALSE: 0,
    TRUE: 1
  },
  matchingMailSendFlg:{
    FALSE: 0,
    TRUE: 1
  }
}
