module.exports = {
  // 媒体種別
  type: {
    'b': {name: '買い', name_en: 'buy'},
    's': {name: '売り', name_en: 'sell'},
    'e': {name: 'その他', name_en: 'other'},
  },

  //媒体カテゴリ
  category: {
    '1': {name: 'ネット関係'},
    '2': {name: '紙媒体'},
    '3': {name: '紹介（社外）'},
    '4': {name: '紹介（社内）'},
    '5': {name: 'リピーター'},
    '6': {name: '看板'},
    'e': {name: 'その他'},
  },
  pagination: {initPage: 1, pageSize: 50, pageSize20: 20},
  noDataText: '未設定',
}
