module.exports = {
  // 更新に不要なプロパティ
  deleteKeyList: ['__typename',],

  pagination: {initPage: 1, pageSize: 50,},

  // お問い合わせ
  responseType: [
    {text: '買い', value: 'buy', type: 'b'},
    {text: '売り', value: 'sell', type: 's'},
    {text: 'その他', value: 'other', type: 'e'}
  ],

  //物件区分
  bukkenKubun: [
    {text: '売土地', value: '10'}, {text: '分譲地', value: '11'},
    {text: 'マンション部屋', value: '21'}, {text: '戸建', value: '30'}, {text: '事業用', value: '40'},
  ],

  //公開ステータス
  statusPropertyOpen: [
    {text: '非公開', value: '1'}, {text: '公開', value: '2'}, {text: '未設定', value: ''}
  ],

  //物件ステータス
  statusProperty: [
    {text: '新規物確中', value: '2'}, {text: '収集後未対応', value: '3'}, {text: '作成中', value: '4'},
    {text: '参考情報（紹介不可）', value: '5'}, {text: '取扱可（商談なし）', value: '10'}, {text: '取扱可（他社商談）', value: '11'},
    {text: '取扱可（自社商談）', value: '12'}, {text: '成約（他社済み）', value: '14'}, {text: '成約（自社済み）', value: '15'},
    {text: '売止', value: '16'}, {text: '査定中', value: '17'}, {text: '非元付（先物）', value: '18'}, {text: '未設定', value: ''}
  ],

  //交通
  transportation1: 1,

  //預かり種別
  azukari_shubetsu: [{text: '他社', value: '0'}, {text: '自社', value: '1'}, {text: '未設定', value: ''},],

  //自社・他社の定数
  azukari_company: {
    otherCompany: '0',
    ownCompany: '1',
  },

  //取引態様
  torihiki_taiyou:  [
    {text: '売主', value: '1'}, {text: '代理', value: '2'}, {text: '専属専任', value: '3'},
    {text: '専任', value: '4'}, {text: '一般', value: '5'}, {text: '媒介', value: '6'},
  ],

  //データなしの表示
  noDataText: '未設定',

  //ラベル名
  labelName: {
    inquiryNumber: '反響番号',
    shopLabel: '店舗',
    inquiryLabel: 'お問い合わせ種別',
    baitaiLabel: '媒体',
    responseDateLabel: '反響日時',
    responsePropertyLabel: '対象物件',
    responseDetailLabel: 'お問い合わせ内容',
    targetProperty: '対象物件',
    name: '氏名',
    address: '住所',
    tel: '電話番号',
    email: 'メールアドレス',
    contactMethod: '希望連絡方法',
    contactTime: '希望連絡時間',
    inquiryContents: 'お問い合わせ内容',
  },

  //顧客選択モーダルの顧客表示数
  customerListCount: 20,
  // 媒体の件数用に
  mediumPageSize: 500,

  path: {
    basicInformation: [
      'tanto_tenpo',
      'kind',
      'baitai_id',
      'receive_at',
      'inquiry_properties_sonota',
      'inquiry_content',
      'name_last',
      'name_first',
      'zip',
      'address_string',
      'tel',
      'mail',
      'request_contact_method',
      'request_contact_time_to',
      'request_contact_time_end',
      'inquiry_content',
    ]
  },

  defaultStaff: 0,
  defaultStaffOption: {key: null, value: '未設定'},

  //ソート
  defaultSortTarget: 'receive_at',
  sortTarget: [
    {
      key: 'receive_at',
      value: '反響日時',
    },
    {
      key: 'inquiry',
      value: '反響番号',
    },
  ]
}
