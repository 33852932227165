module.exports = {
  deleteKeyList: [
    'clientHistoryFiles', 'clientHistoryKibous', 'clientHistoryProperties', 'clientRelation', 'lineClientRelation', 'lineReceiveRelation',
    'mailClientRelation', 'mailReceive'
  ],
  action: [
    {text: '現地案内', value: '01'}, {text: '電話（非通電）', value: '02'}, {text: '電話（通電）', value: '03'},
    {text: '来店面談', value: '04'}, {text: '訪問面談', value: '05'}, {text: 'メール', value: '06'},
    {text: 'LINE', value: '07'}, {text: 'SNS', value: '08'}, {text: 'FAX', value: '09'},
    {text: '資料投函', value: '10'}, {text: '資料郵送', value: '11'}, {text: 'オンライン面談', value: '12'},
    {text: 'OH・イベント来場', value: '13'}, {text: '対面査定', value: '14'}, {text: '非対面査定', value: '15'},
    {text: 'その他（対面）', value: '16'}, {text: 'その他（非対面）', value: '17'}, {text: 'メール開封', value: '18'},
    {text: 'メール リンクトラッキング', value: '19'}, {text: 'LINE リンクトラッキング', value: '20'},
  ],

  actionGuide: {
    local: ['01'],
    onsite: ['02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17']
  },

  pagination: {initPage: 1, pageSize: 10},

  // 絞り込み 設定項目
  searchParam: {
    rank: [
      {text: 'A', value: 'A'}, {text: 'B', value: 'B'}, {text: 'C', value: 'C'},
      {text: 'D', value: 'D'}, {text: 'E', value: 'E'}, {text: 'F', value: 'F'},
      {text: 'G', value: 'G'},
    ],
    type: [
      {text: '買い', value: 'buy'}, {text: '売り', value: 'sell'},
    ],
    status: [
      {text: '未対応', value: 0}, {text: '将来見込み', value: 1}, {text: '追客中', value: 2},
      {text: '他決', value: 3}, {text: '中止', value: 4}, {text: '保留', value: 5},
      {text: '他社受託', value: 6}, {text: '自社受託', value: 7}, {text: '売止', value: 8},
      {text: '買付済', value: 9}, {text: '契約済', value: 10}, {text: '決済済', value: 11},
    ],
    from: [
      {text: '顧客', value: 'c'}, {text: 'スタッフ', value: 's'},
    ],
    expectation: [
      {text: '100', value: 100}, {text: '80', value: 80}, {text: '60', value: 60},
      {text: '40', value: 40}, {text: '20', value: 20}, {text: '0', value: 0},
    ],
    register: [
      {text: '未登録', value: 0,}, {text: '全国', value: 1,}, {text: '決済済', value: 2,},
    ],
    mail: [
      {text: '許可', value: 0,}, {text: '未許可', value: 1,},
    ],
    regularAction: [
      {text: '現地案内', value: '01'}, {text: '電話（通電）', value: '03'},
      {text: 'メール', value: '06'}, {text: 'LINE', value: '07'},
    ],
    allAction: [
      {text: '現地案内', value: '01'}, {text: '電話（非通電）', value: '02'},
      {text: '電話（通電）', value: '03'}, {text: '来店面談', value: '04'},
        {text: '訪問面談', value: '05'}, {text: 'メール', value: '06'},
      {text: 'LINE', value: '07'}, {text: 'SNS', value: '08'},
      {text: 'FAX', value: '09'}, {text: '資料投函', value: '10'},
      {text: '資料郵送', value: '11'}, {text: 'オンライン面談', value: '12'},
      {text: 'OH・イベント来場', value: '13'}, {text: '査定提示（対面）', value: '14'},
      {text: '査定提示（非対面）', value: '15'}, {text: 'その他（対面）', value: '16'},
      {text: 'その他（非対面）', value: '17'}, {text: 'メール開封', value: '18'},
      {text: 'メール リンクトラッキング', value: '19'}, {text: 'LINE リンクトラッキング', value: '20'},
    ],
  },

  //アクション設定のモーダル
  actionParam: {
    date: { // 日時
      // day:
      type: [
        {text: "予定", value: 0}, {text: "完了", value: 1}
      ]
    },
    type: [ // 案件 TODO 削除
      {text: '居住用（一戸建て）', value: 0},
      {text: '事業用（一棟ビル）', value: 1},
      {text: '売り（〇〇町／一戸建て／1,200万円）', value: 2},
    ],
    who: [ // 誰が
      {text: "顧客", value: 'c'}, {text: "スタッフ", value: 's'}
    ],
    action: { // アクション
      staff: [
        {text: '現地案内', value: '01'}, {text: '電話（非通電）', value: '02'},
        {text: '電話（通電）', value: '03'}, {text: '来店面談', value: '04'}, {text: '訪問面談', value: '05'},
        {text: 'メール', value: '06'}, {text: 'LINE', value: '07'},
        {text: 'SNS', value: '08'}, {text: 'FAX', value: '09'},
        {text: 'オンライン面談', value: '12'}, {text: 'OH・イベント来場', value: '13'},
        {text: '資料投函', value: '10'}, {text: '資料郵送', value: '11'},
        {text: '査定提示（対面）', value: '14'}, {text: '査定提示（非対面）', value: '15'},
        {text: 'その他（対面）', value: '16'}, {text: 'その他（非対面）', value: '17'},
      ],
      client: [
        {text: '現地案内', value: '01'}, {text: '電話（通電）', value: '03'},
        {text: '来店面談', value: '04'}, {text: 'メール', value: '06'},
        {text: 'LINE', value: '07'}, {text: 'SNS', value: '08'},
        {text: 'FAX', value: '09'}, {text: 'オンライン面談', value: '12'},
        {text: 'OH・イベント来場', value: '13'}, {text: 'その他（対面）', value: '16'},
        {text: 'その他（非対面）', value: '17'},
      ]
    },
  },

  //物件ステータス
  statusProperty: [
    {text: '新規物確中', value: '2'}, {text: '収集後未対応', value: '3'}, {text: '作成中', value: '4'},
    {text: '参考情報（紹介不可）', value: '5'}, {text: '取扱可（商談なし）', value: '10'}, {text: '取扱可（他社商談）', value: '11'},
    {text: '取扱可（自社商談）', value: '12'}, {text: '成約（他社済み）', value: '14'}, {text: '成約（自社済み）', value: '15'},
    {text: '売止', value: '16'}, {text: '査定中', value: '17'}, {text: '非元付（先物）', value: '18'}, {text: '未設定', value: ''}
  ],

  //コンタクト方法
  contactApproval: 'OK',
  contactUnapproved: 'NG',

  path: {
    basicInformation: [
      'nitiji',
      'nitiji_end',
      'kibous',
      'practitioner',
      'action_type',
      'action_shop_user',
      'appointment_shop_user',
      'title',
      'body',
      'torokugai_property',
    ],
  },
  defaultStaff: 0,
  defaultStaffOption: {key: null, value: '未設定'},

  //メールの送信確認
  mailSendFlg: {
    ready: '1',
    success: '2',
    failure: '3',
  },
  mailType: '01',
  pathMail: {
    basicInformation: [
      'title',
      'body',
    ],
  },
  practitioner: {
    customer: 'c',
    staff: 's',
  },
  actionType: {
    localInformation: '01',
    nonEnergized: '02',
    energized: '03',
    storeVisitInterview: '04',
    visitInterview: '05',
    email: '06',
    LINE: '07',
    SNS: '08',
    FAX: '09',
    documentPosting: '10',
    documentMailing: '11',
    onlineInterview: '12',
    OH: '13',
    faceToFaceAssessment: '14',
    nonFaceToFaceAssessment: '15',
    otherFaceToFace: '16',
    otherNonFaceToFace: '17',
    emailOpening: '18',
    emailLinkTracking: '19',
    lineLinkTracking: '20',
  },
  planFlg: {
    planned: 'p',
    completed: 'e',
  },
  cancelFlg: {
    slash: '0',
    cancel: '1',
  },
  calendar: {
    slash: '0',
    addCalendar: '1',
  },
  lineTitle: 'LINEメッセージを送信しました',
  noDataText: '未設定',
  defaultSender: 0,

  weekDays: [
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日',
    '日曜日',
  ],
  analyticsHourDivisions: [1, 3, 6, 12],
  defaultAnalyticsHourDivision: 1,

  defaultScrollPagination: {
    page: 1,
    pageSize: 20,
    pageCount: 0,
    rowCount: 0,
  },
}
