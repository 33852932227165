module.exports = {
  status: [
    {text: "将来見込み", value: 100},
    {text: "未対応", value: 200},
    {text: "追客中", value: 300},
    {text: "他社受託", value: 410},
    {text: "自社受託", value: 420},
    {text: "他決", value: 510},
    {text: "中止", value: 520},
    {text: "保留", value: 530},
    {text: "売止", value: 540},
    {text: "break", value: 'break'},
    {text: "買付済", value: 550},
    {text: "契約済", value: 600},
    {text: "決済済", value: 700},
  ],
  expectation: {'100': '100℃', '80': '80℃', '60': '60℃', '40': '40℃', '20': '20℃', '0': '0℃'},
  kibouShubetsu: {
    'r10': '戸建て',
    'r20': '分譲マンション',
    'r30': '土地',
    'b10': '店舗、事務所',
    'b20': '店舗、事務所付き住宅',
    'b30': '一棟ビル',
    'b40': '一棟マンション',
    'b50': 'その他'
  },
  baikai_type: {
    '1': '一般媒介',
    '2': '専任媒介',
    '3': '専属専任媒介',
  },
  desiredType: {
    kibouKodateNew: '新築戸建 ',
    kibouKodateOld: '中古戸建 ',
    kibouMansionNew: '新築マンション ',
    kibouMansionOld: '中古マンション ',
    kibouTochi: '土地 ',
    kibouJigyo: '事業用 ',
  },
  noDataText: '未設定',
}
